import genAiService from "@/services/genAiService";
import {
  AiAssistantChunkResults,
  type AiAssistantResponseResult,
} from "@/services/genAiService/types";
export default {
  namespaced: true,
  state: {
    queryResults: [] as Array<{
      result: AiAssistantResponseResult;
      chunks: string[];
    }>,
    chunkResults: [] as AiAssistantChunkResults[],
  },
  getters: {
    queryResults: (state: any) => state.queryResults,
    chunkResults: (state: any) => state.chunkResults,
  },
  mutations: {
    emptyQueryResults(state: any) {
      state.queryResults = [];
    },
    emptyChuckResults(state: any) {
      state.chuckResults = [];
    },
    setQueryResults(
      state: any,
      { index, result }: { index: number; result: AiAssistantResponseResult }
    ) {
      state.queryResults[index] = { result, chunks: [] };
    },
    setAnswerChunks(
      state: any,
      { index, chunk }: { index: number; chunk: string }
    ) {
      if (!state.chunkResults[index]) {
        state.chunkResults[index] = [];
      }
      state.chunkResults[index].push(chunk);
    },
  },
  actions: {
    async processQuery({ commit }: any, queryObj: any) {
      try {
        const index = queryObj.index;

        // Initialize the query result with the first chunk
        let firstChunkSent = false;
        const handleChunk = (chunk: string) => {
          if (!firstChunkSent) {
            commit("setQueryResults", {
              index,
              result: {
                answer: chunk,
                type: "success",
                user_query: queryObj.user_query,
              },
            });
            firstChunkSent = true;
          }
          commit("setAnswerChunks", { index, chunk });
        };
        await genAiService.handleProcessQuery(queryObj, handleChunk);
      } catch (e: unknown) {
        let err: string;

        if (typeof e === "string") {
          err = e;
        } else if (e instanceof Error) {
          err = e.message;
        } else {
          err = "Something went wrong.";
        }

        const result: AiAssistantResponseResult = {
          answer: err,
          type: "error",
          user_query: queryObj.user_query,
        };

        commit("setQueryResults", { index: queryObj.index, result });
        throw e;
      }
    },
  },
};
