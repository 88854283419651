<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import CiscoBtn from "@/components/CiscoBtn.vue";
import cases from "@/layout/InterestSurvey/assets/cases.json";
import assocImg from "@/layout/InterestSurvey/assets/icon-Associate.png?url";
import expImg from "@/layout/InterestSurvey/assets/icon-Expert.png?url";
import novImg from "@/layout/InterestSurvey/assets/icon-Novice.png?url";
import profImg from "@/layout/InterestSurvey/assets/icon-Professional.png?url";
import SurveyModelHeader from "@/layout/InterestSurvey/assets/SurveyModelHeader.vue";
import SurveyPagination from "@/layout/InterestSurvey/assets/SurveyPagination.vue";
import {
  LearningOptions,
  PreferredOptions,
  Question,
  Survey,
} from "@/layout/InterestSurvey/InterestSurvey";
import { fetchAll } from "@/services/catalogService/certifications";
import profileService from "@/services/profileService";
import { EventBus } from "@/store/eventBus";
import survey from "@/utils/survey";

export default defineComponent({
  emits: ["closePersonalInterestSurvey"],
  components: { SurveyPagination, SurveyModelHeader, CiscoBtn },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preferred_options: [] as PreferredOptions[],
      pref_topics: [] as PreferredOptions[],
      learning_options: [] as LearningOptions[],
      learning_topics: [] as LearningOptions[],
      certification_topics: [] as LearningOptions[],
      certification_options: [] as LearningOptions[],
      questions: [] as Question[],
      options: [],
      survey: {} as Survey,
      toggle: true,
      closeSurvey: true,
      current_slide_number: 0,
      cases,
      certifications_survey: [] as any,
    };
  },
  mounted() {
    this.fetchObSurvey()
      .then(() => {
        if (this.obSurvey) {
          this.survey = this.obSurvey[0] as Survey;
          this.questions = this.survey.questions;
          this.preferred_options = this.survey.questions[0]
            .options as PreferredOptions[];
          this.learning_options = this.survey.questions[1]
            .options as LearningOptions[];
          this.certification_options = this.survey.questions[2].categories;
        }
      })
      .catch(() => {
        // intentional
      });
    // @ts-ignore
    this.$store.subscribe((mutation: any, state: any) => {
      if (
        mutation.type === "user/setupToggleShowAnyway" &&
        mutation.payload === true
      ) {
        this.resetSurvey();
      }
    });
    this.getCertification();
  },
  computed: {
    ...mapGetters({
      showAnyway: "user/showAnyway",
      obSurvey: "user/obSurvey",
      userStatus: "user/status",
    }),
    isForYou(): boolean {
      return this.show && this.$route && this.$route.name == "ForYou";
    },
  },
  methods: {
    ...mapMutations({
      setupToggleShowAnyway: "user/setupToggleShowAnyway",
      setObSurvey: "user/setObSurvey",
    }),
    ...mapActions({
      fetchStatus: "user/fetchStatus",
    }),
    async fetchObSurvey() {
      await profileService.surveys
        .fetchOnboardingSurvey()
        .then((data) => this.setObSurvey(data))
        .catch(() => {
          // do nothing
        });
    },
    currentSlideNum(value: number): boolean {
      return value == this.current_slide_number;
    },
    getCertImage(icon: string): string | undefined {
      switch (icon) {
        case "Entry":
          return novImg;
        case "Associate":
          return assocImg;
        case "Professional":
          return profImg;
        case "Expert":
          return expImg;
      }
    },
    getQuestionTitle(): string {
      return this.questions[this.current_slide_number]
        ? this.questions[this.current_slide_number].name
        : "";
    },
    getQuestionType(): string {
      if (
        this.questions[this.current_slide_number] &&
        this.questions[this.current_slide_number].type === "mcsa"
      ) {
        return "(Choose one)";
      }
      return "(Choose all that apply)";
    },
    resetSurvey() {
      this.current_slide_number = 0;
      this.closeSurvey = true;
    },
    selectedPreferredOption(val: any) {
      if (this.pref_topics.indexOf(val) === -1) {
        this.pref_topics.push(val);
      } else {
        this.pref_topics = this.pref_topics.filter((item: any) => item !== val);
      }
    },
    selectedLearningOption(val: any) {
      if (this.learning_topics.indexOf(val) === -1) {
        while (this.learning_topics.length > 0) {
          this.learning_topics.pop();
        }
        this.learning_topics.push(val);
      } else {
        this.learning_topics = this.learning_topics.filter(
          (item: any) => item !== val
        );
      }
    },
    selectedCertificationOption(val: any) {
      this.certification_topics = [
        {
          name: val.name,
          value: val.acronym,
          guid: val.guid,
        } as any,
      ];
    },
    async nextSlide(withoutValue: boolean) {
      if (withoutValue) {
        if (this.current_slide_number == 1) {
          this.learning_topics = [];
        } else {
          this.pref_topics = [];
        }
      }
      this.current_slide_number = this.current_slide_number + 1;
      if (
        (this.pref_topics.length < 1 ||
          this.learning_topics.length < 1 ||
          this.certification_topics.length < 1) &&
        this.current_slide_number == 4
      ) {
        EventBus.emit("updatesurveyCompleted", true);
        this.$emit("closePersonalInterestSurvey");
        survey.telemetry(
          window.env.ONBOARDING_SURVEY,
          this.learning_topics,
          this.pref_topics,
          this.certification_topics
        );
        let response = survey.response(
          this.survey,
          this.questions,
          this.pref_topics,
          this.learning_topics,
          this.certification_topics,
          this.userStatus
        );
        if (await response) {
          // update topics dropdown
          this.emitTopic();
        }
      }
    },
    prevSlide() {
      this.current_slide_number = this.current_slide_number - 1;
    },
    closeModal() {
      this.setupToggleShowAnyway(false);
      this.$emit("closePersonalInterestSurvey");
      this.fetchStatus();
    },
    closeModalandTopics() {
      this.setupToggleShowAnyway(false);
      this.$emit("closePersonalInterestSurvey");
      this.emitTopic();
    },
    emitTopic() {
      EventBus.emit("syncTopics");
    },
    async submit(withoutValue: boolean) {
      if (withoutValue && this.current_slide_number == 2) {
        this.certification_topics = [];
      }
      this.$emit("closePersonalInterestSurvey");
      EventBus.emit("updatesurveyCompleted", true);

      survey.telemetry(
        window.env.ONBOARDING_SURVEY,
        this.learning_topics,
        this.pref_topics,
        this.certification_topics
      );
      let response = await survey.response(
        this.survey,
        this.questions,
        this.pref_topics,
        this.learning_topics,
        this.certification_topics,
        this.userStatus
      );
      if (response) {
        this.nextSlide(withoutValue);
        // update topics dropdown
        this.emitTopic();
      }
    },
    checkTopic(p: any) {
      return this.certification_topics.includes(p);
    },

    async getCertification() {
      await fetchAll()
        .then((result: any) => {
          this.certifications_survey = result.data?.reduce((r: any, e: any) => {
            r[e.certification_level] = [];
            return r;
          }, {});

          result.data?.forEach((certification: any) => {
            this.certifications_survey[certification.certification_level].push(
              certification
            );
          });
          const sortArray = {
            Associate: [],
            Professional: [],
            Expert: [],
          };
          sortArray["Associate"] = this.certifications_survey?.Associate;
          sortArray["Professional"] = this.certifications_survey?.Professional;
          sortArray["Expert"] = this.certifications_survey?.Expert;

          this.certifications_survey = sortArray;
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
</script>

<template>
  <transition name="modal" v-if="isForYou || showAnyway">
    <div
      class="modal-mask fixed left-0 right-0 top-0 z-[9998] h-full w-full overflow-hidden backdrop-blur-[0.4375rem]"
    >
      <div
        class="modal-wrapper h-full overflow-x-hidden overflow-y-scroll py-4 align-middle"
      >
        <div v-if="closeSurvey">
          <div v-if="current_slide_number < 3" data-cy="survey-popup">
            <survey-model-header
              :class="[
                'h-[17.188rem] py-5 xsmobile:h-[20.188rem]',
                {
                  'max-w-[56rem]': current_slide_number == 2,
                  'max-w-[39rem]': current_slide_number != 2,
                },
              ]"
              @close-survey="closeSurvey = !closeSurvey"
            >
              <h4 class="mt-10 text-36 font-light text-white-lightest">
                {{ getQuestionTitle() }}
              </h4>

              <h4 class="my-2 mt-[3.75rem] text-white-lightest">
                {{ getQuestionType() }}
              </h4>
            </survey-model-header>

            <div
              :class="[
                'modal-container relative mx-auto my-0 rounded-b-lg border border-t-0 border-black-light bg-white-lightest py-5 dark:bg-black',
                {
                  'max-w-[56rem]': current_slide_number == 2,
                  'max-w-[39rem]': current_slide_number != 2,
                },
              ]"
            >
              <div class="modal-body my-5 px-[1.875rem] pb-10 text-black-dark">
                <slot name="body">
                  <div v-if="current_slide_number === 0" class="items-center">
                    <div class="mt-8">
                      <div class="ml-2 mt-6">
                        <div class="flex flex-wrap justify-center">
                          <div
                            v-for="option in preferred_options"
                            :key="option.id"
                            class="mb-2.5 mr-[0.3125rem] flex"
                          >
                            <cisco-btn
                              data-cy="personal-survey-select-first"
                              @click="selectedPreferredOption(option)"
                              :value="option.value"
                              :active="pref_topics.includes(option)"
                            >
                              {{ option.value }}
                            </cisco-btn>
                          </div>
                        </div>
                        <div class="w-full text-center" />

                        <survey-pagination
                          :show-next="cases.length - 1 != current_slide_number"
                          :allow-next-nav="pref_topics.length > 0"
                          :show-previous="current_slide_number != 0"
                          :current-slide-number="current_slide_number"
                          @first-page="current_slide_number = 0"
                          @second-page="current_slide_number = 1"
                          @third-page="current_slide_number = 2"
                          @nextSlide="nextSlide"
                          @backSlide="prevSlide"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="current_slide_number === 1" class="items-center">
                    <div class="mt-8">
                      <div class="ml-2 mt-6">
                        <div class="flex flex-col items-center justify-between">
                          <div
                            v-for="option in learning_options"
                            :key="option.id"
                            class="m-[0.3rem] flex"
                          >
                            <cisco-btn
                              data-cy="personal-survey-select-first"
                              @click="selectedLearningOption(option)"
                              :value="option.value"
                              :active="learning_topics.includes(option)"
                            >
                              {{ option.value }}
                            </cisco-btn>
                          </div>
                        </div>
                        <div class="w-full text-center" />
                        <survey-pagination
                          :show-next="cases.length - 1 != current_slide_number"
                          :allow-next-nav="learning_topics.length > 0"
                          :show-previous="!currentSlideNum(0)"
                          :current-slide-number="current_slide_number"
                          @first-page="current_slide_number = 0"
                          @second-page="current_slide_number = 1"
                          @third-page="current_slide_number = 2"
                          @nextSlide="nextSlide"
                          @backSlide="prevSlide"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="current_slide_number === 2" class="items-center">
                    <form>
                      <div class="ml-10 mr-10">
                        <div
                          class="flex h-[25rem] flex-[1_1_230px] flex-col flex-wrap items-center justify-start text-sm xsmobile:h-[72.1875rem]"
                          v-if="Object.values(certifications_survey).length"
                        >
                          <div
                            data-cy="personal-survey-certification-option"
                            v-for="(options, name) in certifications_survey"
                            :key="options.id"
                            class="sm:w-40 md:w-[13.125rem] lg:w-[14.375rem] xsmobile:w-[14.375rem]"
                          >
                            <div
                              class="flex h-10 items-center rounded-md bg-gray-100 text-base"
                            >
                              <img
                                class="ml-2 h-5 w-5"
                                :src="getCertImage(name.toString())"
                                :alt="name.toString()"
                              />
                              <p class="ml-2">{{ name }}</p>
                            </div>

                            <ul
                              class="my-2 ml-2"
                              data-cy="personal-survey-certification-option-options"
                              v-for="p in options"
                              :key="p.id"
                            >
                              <li class="contents leading-5">
                                <label
                                  class="cursor-pointer"
                                  :for="`category_.${p.id}`"
                                >
                                  <input
                                    :id="`category_.${p.id}`"
                                    type="radio"
                                    name="category"
                                    :checked="checkTopic(p)"
                                    @change="selectedCertificationOption(p)"
                                  />
                                  <span class="-mt-[0.3125rem] ml-2">{{
                                    p.name
                                  }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="w-full text-center">
                      <survey-pagination
                        :show-next="cases.length - 1 != current_slide_number"
                        :allow-next-nav="certification_topics.length > 0"
                        :show-previous="!currentSlideNum(0)"
                        :current-slide-number="current_slide_number"
                        :submit-btn="true"
                        @first-page="current_slide_number = 0"
                        @second-page="current_slide_number = 1"
                        @third-page="current_slide_number = 2"
                        @nextSlide="submit"
                        @backSlide="prevSlide"
                      />
                    </div>
                  </div>
                </slot>
              </div>
            </div>
          </div>

          <div v-if="current_slide_number === 3">
            <survey-model-header
              class="max-w-[39rem]"
              @close-survey="closeModal"
            />
            <div
              class="modal-container relative mx-auto my-0 min-h-[39.375rem] max-w-[39rem] rounded-b-lg border border-t-0 border-black-light bg-black-dark py-5 dark:bg-black"
            >
              <div class="ml-2 mt-6 flex items-center pb-[9.375rem]">
                <div>
                  <p
                    class="ml-[3.125rem] mr-[3.125rem] flex flex-col text-center text-36 font-extralight text-white-lightest"
                  >
                    Thank you for sharing your learning interests with us. You
                    can always go back and retake this survey.
                  </p>
                  <br />
                  <p
                    class="ml-[3.125rem] mr-[3.125rem] flex flex-col text-center text-36 font-extralight text-white-lightest"
                  >
                    You can now enjoy a selection of content personalized for
                    you.
                  </p>
                </div>

                <div class="absolute bottom-0 w-full text-center">
                  <div
                    class="case-slider__navigation mb-8 ml-[1.5625rem] flex w-[90%] flex-row-reverse justify-center"
                  >
                    <button
                      class="btn my-4 inline-flex h-[2.875rem] items-center border-blue-700 bg-blue-light text-white-lightest"
                      tabindex="0"
                      data-cy="close-goal-modal"
                      @click.prevent="closeModal"
                      @keyup.enter.prevent="closeModal"
                      @keyup.space.prevent="closeModal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <survey-model-header
            class="h-[17.188rem] max-w-[39rem] py-5"
            @close-survey="closeSurvey = !closeSurvey"
          >
            <h4
              class="is-active-slide mt-10 text-36 font-light text-white-lightest"
            >
              Are you sure you want to exit?
            </h4>

            <h4 class="is-active-slide my-2 mt-[3.75rem] text-white-lightest">
              You can share your learning interests anytime through the profile
              card.
            </h4>
          </survey-model-header>

          <div
            class="modal-container relative mx-auto my-0 max-h-[10.313rem] max-w-[39\rem] rounded-b-lg border border-t-0 border-black-light bg-white-lightest py-5 dark:bg-black"
          >
            <div class="modal-body my-5 px-[1.875rem] pb-10 text-black-dark">
              <slot name="body">
                <div class="container items-center">
                  <div class="mt-8">
                    <div class="ml-2 mt-6">
                      <div class="w-full text-center" />

                      <div
                        class="case-slider__navigation mb-8 flex flex-row-reverse justify-between"
                      >
                        <div
                          class="next"
                          v-if="cases.length - 1 != current_slide_number"
                        >
                          <button
                            data-cy="exit-survey-button"
                            class="btn my-4 inline-flex h-[2.875rem] items-center bg-blue-light text-white-lightest"
                            @click.prevent="closeModalandTopics"
                            @keyup.enter.prevent="closeModalandTopics"
                            @keyup.space.prevent="closeModalandTopics"
                          >
                            Yes, exit
                          </button>
                        </div>
                        <div
                          class="prev"
                          v-if="cases.length - 1 != current_slide_number"
                        >
                          <button
                            class="btn my-4 inline-flex h-[2.875rem] items-center bg-transparent"
                            @click="closeSurvey = !closeSurvey"
                          >
                            No, continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="css" scoped>
.active {
  background: rgb(29 78 216 / var(--tw-bg-opacity));
  border-radius: 100px;
  padding: theme("padding.4");
  background-color: #0051af;
  color: white;
}

[type="radio"]:checked {
  background-image: url("@/layout/InterestSurvey/assets/radiobutton.png");
}

.transformscale-y {
  transform: scaleY(0.7);
}

.contents {
  display: contents;
}

.grid {
  display: grid;
}

.inherit {
  margin-top: inherit;
}

.modal-mask {
  transition: opacity 0.3s ease;
}

.modal-container {
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

.submitBtn {
  background-color: #0051af;
}

button:focus {
  outline: none;
  box-shadow: unset !important;
}

label {
  display: flex;
  flex: 1;
  align-items: flex-start;
  padding-top: 1rem;
}
</style>
