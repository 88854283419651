<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";

import { defineComponent } from "vue";

import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  props: {
    viewAllTag: {
      type: String,
      default: "",
    },
  },
  methods: {
    isUnauth,
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>
<template>
  <div class="px-4 pb-20 lg:px-44" data-cy="explore-more-resources">
    <div class="mb-4 text-41 text-black-light" data-cy="explore-more-title">
      Explore more training resources
    </div>
    <div
      class="mb-10 w-full text-18 text-black-light lg:w-1/2"
      data-cy="explore-more-desc"
    >
      Cisco U. includes videos, tutorials, virtual events, Learning Paths, and
      more to help you reach your learning goals. Explore the catalog to
      discover additional learning possibilities.
    </div>
    <router-link
      tabindex="0"
      @click="sendUnauthTelemetry('Explore catalog')"
      :data-cy="viewAllTag"
      :to="{
        name: 'Explore',
      }"
      class="text-blue-light hover:text-blue md:relative md:bottom-0 md:inline-block"
    >
      Explore catalog
      <cu-icon-chevron-right size="s"></cu-icon-chevron-right>
    </router-link>
  </div>
</template>
