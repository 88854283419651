<script lang="ts">
import "@cisco-u/pagination/cu-pagination.js";
import "@cisco-u/icon-toggle/cu-icon-toggle.js";
import "@cisco-u/icons/icons/cu-icon-view-grid.js";
import "@cisco-u/icons/icons/cu-icon-list.js";
import "@cisco-u/icons/icons/cu-icon-line-diagonal.js";

import { startCase } from "lodash-es";
import { defineComponent } from "vue";

import sortDropdown from "@/components/sortDropdown.vue";
import { fetchTypeResults } from "@/services/searchService/searchType";
import type {
  SearchRequest,
  SearchResponse,
} from "@/services/searchService/types";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";
import NoResults from "@/views/Search/assets/NoResults.vue";
import RelSingleSection from "@/views/Search/assets/RelSingleSection.vue";
import TopCourses from "@/views/Search/assets/TopCourses.vue";
import { buildUrlParmsfromFacetFilters } from "@/views/Search/filtersHelper";
import SearchFilter from "@/views/Search/SearchFilterSidebar/SearchFilter.vue";
import type { FacetFilter } from "@/views/Search/SearchTypes";
import { PageNumEvent } from "@/views/Search/SearchTypes";
import SkeletonLoader from "@/views/Skeleton/Search/SkeletonLoader.vue";

const videoTrack = "video-track";
const videoSeries = "video-series";
const instructorLed = "instructor-led";
const practiceExam = "practice-exam";
const podcasts = "podcasts";
const events = "events-and-webinars";

export default defineComponent({
  components: {
    SearchFilter,
    NoResults,
    RelSingleSection,
    sortDropdown,
    SkeletonLoader,
    TopCourses,
  },
  props: {
    query: {
      type: String,
      default: "",
    },
    assesments: {
      type: String,
      default: "",
    },
    labs: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      facetFilters: [] as Array<FacetFilter>,
      response: null as SearchResponse | null | undefined,
      isSearching: false,
      seeAllType: "",
      hasSearched: false,
      current: 1,
      size: 21,
      sort: "soonest",
      showSoonest: false,
      to: null as string | [] | null,
      closeAll: true,
      cardView: "Grid",
      iscardViewDisplay: false,
      url: "https://www.cisco.com/c/en/us/training-events/training-certifications/training-catalog.html?delivery_options=instructor_led",
    };
  },
  computed: {
    isSearchRoute(): boolean {
      return this.$route && this.$route.name == "search-type";
    },
    totalPages(): number {
      if (!this.response) return 0;
      return this.pageMeta ? this.pageMeta.total_pages : 0;
    },
    page() {
      return {
        current: this.current,
        size: this.size,
      };
    },
    pageMeta() {
      return this.response?.meta?.page ?? null;
    },
    typeResults() {
      return this.response?.results ?? [];
    },
    showBanner() {
      return [practiceExam, videoSeries, podcasts, events].includes(
        this.$route?.meta?.type as string
      );
    },
    requestId() {
      return this.response?.meta?.request_id ?? "0";
    },
  },
  watch: {
    $route() {
      let routerName: any = this.$route.name;
      if (!this.isSearchRoute && !routerName.startsWith("explore-")) return;
      this.current = 1;
      this.sort = "soonest";
      this.facetFilters = [];
      this.hasSearched = false;
      this.response = null;
      this.initializeSearch();
    },
    current() {
      if (this.current == 1 && !this.hasSearched) return;
      this.fetchSearchResults();
    },
    seeAllType() {
      if (this.$route?.path && !this.$route?.path?.includes("/search")) {
        let x = this.seeAllType.replaceAll("-", " ");
        document.title = "Cisco U. | " + this.$route.meta.title + startCase(x);
      }
    },
  },
  mounted() {
    const pagination = document.querySelector("cu-pagination");
    pagination?.addEventListener("page-num", (event: PageNumEvent) => {
      this.current = event.detail ? event.detail.pageNum : 1;
    });
    this.initializeSearch();
  },
  methods: {
    isUnauth,
    updateCurrent(pageNum: number) {
      this.current = pageNum;
    },
    setSeeAll(type: string): string {
      let returnType = "course";
      this.iscardViewDisplay = true;
      switch (type) {
        case "path":
        case "learning-paths":
        case "paths":
          returnType = "path";
          break;
        case "challenge":
        case "challenges":
          if (window.env.ENABLE_CTF) {
            returnType = "challenge";
          }
          break;
        case "webinar":
        case "events-and-webinars":
        case "webinars":
        case "events":
          returnType = "webinar";
          break;
        case instructorLed:
          returnType = instructorLed;
          break;
        case "podcast":
        case "podcasts":
          returnType = "podcast";
          break;
        case "video":
        case "videos":
          returnType = "video";
          break;
        case videoTrack:
        case videoSeries:
          returnType = videoTrack;
          break;
        case "tutorial":
        case "tutorials":
          returnType = "tutorial";
          break;
        case practiceExam:
        case "practice-exams":
        case "exams":
          returnType = practiceExam;
          break;
        case "courses":
          break;
      }
      return returnType;
    },
    initializeSearch() {
      if (this.isSearchRoute) {
        this.sort = "relevance";
      }
      if (this.$route?.meta?.type) {
        this.seeAllType = this.setSeeAll(this.$route.meta.type as string);
      }
      if (this.$route?.params?.type) {
        this.seeAllType = this.setSeeAll(this.$route.params.type as string);
      }
      if (this.seeAllType == "" && this.$route?.params?.explore) {
        this.seeAllType = this.setSeeAll(this.$route.params.explore as string);
      }
      if (this.seeAllType == "webinar") {
        this.sort = "soonest";
        this.showSoonest = true;
      } else {
        this.showSoonest = false;
      }
      this.fetchSearchResults();
    },
    setFacetFilters(filters: Array<FacetFilter>) {
      this.facetFilters = filters;
      // When the filters are changed, the current page is reset to 1.
      this.current = 1;
      this.search(this.seeAllType);
    },
    search(contentType: string | undefined) {
      this.hasSearched = false;
      this.isSearching = true;
      let filters = this.facetFilters;
      if (contentType && ["labs", "assessments"].includes(contentType)) {
        contentType = this.isSearchRoute ? "path" : "learning-paths";
      }
      let urlParms = buildUrlParmsfromFacetFilters(filters);
      let routeQuery = {
        ...urlParms,
      } as any;
      if (this.isSearchRoute) {
        routeQuery.query = (this.$route?.query?.query as string) ?? "";
      }
      let routeName = this.checkRouteName(contentType);
      routeQuery = this.cleanRouteQuery(routeQuery, contentType);
      if (contentType == videoTrack) {
        contentType = videoSeries;
      }

      if (contentType) {
        routeName = this.checkRouteName(contentType);
      }

      this.$router.push({
        name: routeName,
        query: routeQuery,
      });
    },
    fetchSearchResults() {
      let postObj = {
        query: (this.$route?.query?.query as string) ?? "",
        page: this.page,
        sort: this.sort,
        type: this.seeAllType,
      } as SearchRequest;
      if (this.to && this.to.length > 0) {
        postObj.to = this.to;
      }
      if (!this.isSearchRoute) {
        postObj.explore = true;
      }

      fetchTypeResults(postObj, this.$route.query, this.isUnauth())
        .then((data) => {
          this.isSearching = false;
          this.hasSearched = true;
          this.response = data;
        })
        .catch(() => {
          this.isSearching = false;
          this.hasSearched = true;
        });
    },
    sortSearch(option: string) {
      this.sort = option;
      this.fetchSearchResults();
    },
    updateTo(toValue: string | [] | null) {
      this.to = toValue;
      this.fetchSearchResults();
    },
    toggleSeeAll(type: string) {
      if (type == videoSeries) {
        type = videoTrack;
      }
      const searchType = this.seeAllType == type ? undefined : type;
      this.search(searchType);
    },
    cleanRouteQuery(routeQuery: any, ctype: string | undefined) {
      if (
        ctype != "path" &&
        routeQuery.type !== undefined &&
        routeQuery.type !== ""
      ) {
        delete routeQuery.type;
      }
      switch (ctype) {
        case "path":
        case "course":
        case "challenge":
          delete routeQuery.certification_level;
          break;
        case practiceExam:
          delete routeQuery.technology;
          delete routeQuery.publisher;
          delete routeQuery.levels;
          break;
        case instructorLed:
        case "tutorial":
        case "video":
        case videoTrack:
          delete routeQuery.publisher;
          delete routeQuery.subscription;
          delete routeQuery.certification_level;
          break;
        case "podcast":
          delete routeQuery.publisher;
          delete routeQuery.subscription;
          delete routeQuery.certification_level;
          delete routeQuery.levels;
          break;
      }
      return routeQuery;
    },
    checkRouteName(ctype: string | undefined): string {
      let routeName: any = this.$route?.name ? this.$route.name : "explore";
      if (routeName == "explore-practice-exams") {
        routeName = "explore-exams";
      }
      if (ctype) {
        return this.isSearchRoute ? "search-type" : routeName;
      }
      return this.isSearchRoute ? "search-all" : "all";
    },
    toggleCardView(event: any) {
      this.cardView = event?.detail?.text;
    },
    async sendTelem() {
      await telemetry.click_on_cta(
        this.$route.path,
        "Training Catalog",
        this.isUnauth(),
        "link"
      );
    },
  },
});
</script>

<template>
  <div class="mx-auto mb-20 px-4 pb-20 md:container">
    <div class="flex w-full flex-col md:flex-row">
      <SearchFilter
        :page="page"
        @filters-updated="setFacetFilters"
        @update-to="updateTo"
        @toggle-see-all="toggleSeeAll"
        :showBanner="showBanner"
      />
      <div class="mb-10 flex flex-grow flex-col flex-wrap">
        <div
          class="flex w-full flex-grow xl:ml-4"
          v-if="typeResults != null && typeResults.length > 0 && !isSearching"
        >
          <rel-single-section
            :result-type="seeAllType"
            @see-more="toggleSeeAll"
            :results="typeResults"
            :page="page"
            :is-searching="isSearching"
            :card-view="cardView"
            :requestId="requestId"
          >
            <div class="flex gap-2">
              <sortDropdown
                @sort="sortSearch"
                :selectedSort="sort"
                :showSoonest="showSoonest"
                class="self-end"
                v-if="!isSearchRoute"
              />
              <cu-icon-toggle
                @cu-icon-toggle-start="toggleCardView"
                :active="cardView == 'Grid' ? 'left' : 'right'"
                data-cy="cu-icon-card-toggle"
                v-if="iscardViewDisplay"
              >
                <span slot="left"
                  ><cu-icon-view-grid></cu-icon-view-grid>Grid</span
                >
                <span slot="right">List<cu-icon-list></cu-icon-list></span>
              </cu-icon-toggle>
            </div>
          </rel-single-section>
        </div>
        <skeleton-loader :limit="size" :grid="true" v-else-if="isSearching" />
        <div v-else-if="hasSearched">
          <NoResults v-if="isSearchRoute" />
          <div class="mb-10 flex flex-col flex-wrap" v-else>
            <div class="my-8 flex items-center">
              <span
                class="show-results pt-[1.5625rem] text-22 font-light leading-[120%] text-black-darker"
              >
                <h2>0 results available</h2>
              </span>
            </div>
          </div>
          <topCourses v-if="isSearchRoute" />
        </div>
      </div>
    </div>
    <div class="mb-5 text-center">
      <cu-links-external
        data-cy="training-catalog-link"
        v-if="$route.path?.includes('/search/instructor-led')"
        type="line-diagonal"
        text="Browse the Training Catalog for more options"
        @click="sendTelem"
        :url="url"
        color="blue-light"
      ></cu-links-external>
    </div>

    <div class="flex w-full justify-center">
      <cu-pagination
        :total="totalPages"
        :current="current"
        @page-num="updateCurrent"
      />
    </div>
  </div>
</template>
