<script lang="ts">
import "@cisco-u/tags/cu-tags.js";
import "@cisco-u/links-external/cu-links-external.js";

import { defineComponent } from "vue";

import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";
import scheduleExam from "@/views/Certifications/assets/images/schedule-cert-exam-img.png?url";

export default defineComponent({
  props: {
    certification: {
      type: Object,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    exCardCore: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scheduleExam,
    };
  },
  methods: {
    isUnauth,
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>
<template>
  <div class="px-4 pb-20 lg:px-44" data-cy="expert-schedule">
    <p class="mb-2 mt-5 text-41 text-black-light">
      <span id="schedule" class="scroll-mt-28">Schedule the exams</span>
    </p>
    <p class="text-18">
      Time to get certified! Schedule and pass your core and lab exams.
    </p>
    <div
      :class="['lg:flex lg:flex-row', { 'mt-4': isUnauth() }]"
      v-if="certification?.exam?.length > 0"
    >
      <div class="mt-10 w-1/2">
        <img :src="scheduleExam" alt="Schedule the Exam" />
      </div>
      <div class="flex flex-col gap-5 lg:flex-row" data-cy="exam-info">
        <div v-for="exams in certification.exam" :key="exams.id">
          <div v-if="exams.type == 'core'" data-cy="core-exam-info">
            <p class="mr-2 text-24 md:text-30">Core exam</p>
            <p
              :class="[
                {
                  'mb-6 text-18 font-normal': isUnauth(),
                  'mb-20 text-22': !isUnauth(),
                },
              ]"
              data-cy="exam-number"
            >
              {{ exams.exam_number + " " + exams.acronym }}
            </p>
            <div class="lg:border-r-2 lg:pr-20">
              <p class="text-11">TIME</p>
              <p class="mb-[2.625rem] text-30" data-cy="exam-duration">
                {{ exCardCore.duration_sec / 60 }} min
              </p>
              <p
                :class="[
                  'font-face mb-2',
                  {
                    'text-xs font-normal tracking-md': isUnauth(),
                  },
                ]"
                v-if="topics.length > 0"
              >
                TOPICS COVERED
              </p>
              <div
                :class="[
                  'flex flex-col gap-2',
                  { 'mb-6': isUnauth(), 'mb-20': !isUnauth() },
                ]"
              >
                <div v-for="topic in topics" :key="topic as string">
                  <cu-tags :text="topic"></cu-tags>
                </div>
              </div>
            </div>
            <cu-links-external
              type="line-diagonal"
              text="Schedule exam"
              @click="sendUnauthTelemetry('Go to CertMetrics')"
              url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
              linkTitle="Go to CertMetrics"
              noUnderline="true"
              color="blue-light"
              class="mt-6"
            ></cu-links-external>
          </div>
          <div
            class="lg:pl-16"
            v-if="exams.type == 'lab'"
            data-cy="lab-exam-info"
          >
            <p class="text-24 md:text-30">Lab exam</p>
            <p
              :class="[
                {
                  'mb-6 text-18 font-normal': isUnauth(),
                  'mb-20 text-22': !isUnauth(),
                },
              ]"
              data-cy="exam-number"
            >
              {{ exams.exam_number + " " + exams.acronym }}
            </p>
            <div>
              <p class="text-11">TIME</p>
              <p class="mb-[2.625rem] text-30" data-cy="exam-duration">
                {{ exams.duration_sec / 60 }} min
              </p>
              <p
                :class="[
                  'font-face mb-2',
                  {
                    'text-xs font-normal tracking-md': isUnauth(),
                  },
                ]"
                v-if="topics.length > 0"
              >
                TOPICS COVERED
              </p>
              <div
                :class="[
                  'flex flex-col gap-2',
                  { 'mb-6': isUnauth(), 'mb-20': !isUnauth() },
                ]"
              >
                <div v-for="topic in topics" :key="topic as string">
                  <cu-tags :text="topic"></cu-tags>
                </div>
              </div>
            </div>
            <cu-links-external
              type="line-diagonal"
              text="Schedule exam"
              @click="sendUnauthTelemetry('Go to lab exam scheduling tool')"
              linkTitle="Go to lab exam scheduling tool"
              url="https://ccie.cloudapps.cisco.com/CCIE/Schedule_Lab/CCIEOnline/CCIEOnline"
              noUnderline="true"
              color="blue-light"
              class="mt-6"
            ></cu-links-external>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
