<script lang="ts">
import "@cisco-u/reicon-lock/cu-reicon-lock.js";

import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import theCard from "@/components/contentBits/theCard.vue";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  components: {
    theCard,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default: null,
    },
    freeContent: {
      type: Boolean,
      default: false,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
  },
  data() {
    return {
      vcoConfig: {
        handler: this.hideClicked,
        events: ["dblclick", "click"],
        capture: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
    }),
    showPremium(): boolean {
      return !this.status?.userEntitlements?.includes(this.result?.guid);
    },
    resourceUrl() {
      let url = this.result.catalog_data
        ? this.result.catalog_data.url
        : this.result.url;

      if (!url || (url && url.length < 5)) {
        url = this.result.id;
      }
      return url;
    },
  },
  methods: {
    isUnauth,
    ...mapActions({
      fetchListByType: "user/fetchListByType",
      removeContentFromMyList: "user/removeContentFromMyList",
      addContentToMyList: "user/addContentToMyList",
    }),

    bookmarkMe(content: any) {
      this.inMyList(content)
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: content,
          })
        : this.addContentToMyList({
            routeName: this.$route.name,
            result: content,
          });
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),
    whereTo() {
      this.sendTelemetry();
    },
    async sendTelemetry() {
      let telemetryResponse = telemetry.external(
        {
          action: "launch-contentType",
          url: this.result.url,
          title: this.result.title,
          id: this.result.id,
          page: this.$route.fullPath,
          type: this.result.type,
          relevance: this.result.relevance,
          sortOrder: this.index,
        },
        this.isUnauth()
      );
      telemetryResponse
        .then(() => {
          if (this.result.url.startsWith("https://")) {
            window.open(this.resourceUrl, "_blank", "noopener");
          } else {
            this.$router.push({ name: "error" });
          }
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
</script>

<template>
  <div
    data-cy="event-result"
    v-show="result"
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView === 'Grid',
        'w-full': cardView != 'Grid',
      },
    ]"
  >
    <the-card
      :is-unauth="isUnauth()"
      :card-view="cardView"
      :result="result"
      :status="status"
      :cy="'event-card'"
      @navigate="whereTo"
      @bookmark="debouncedBookmarkMe(result)"
    />
  </div>
</template>
