export class Duration {
  days: number;
  hours: number;
  minutes: number;

  constructor(days: number, hours: number, minutes: number) {
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
  }
}
