<script lang="ts">
import "@cisco-u/logo/cu-logo.js";

import { defineComponent } from "vue";

export default defineComponent({
  emits: ["close-survey"],
});
</script>

<template>
  <div
    class="mx-auto my-0 overflow-hidden rounded-t-lg border border-b-0 border-black-light bg-black-dark py-5 dark:bg-black"
  >
    <div class="modal-header">
      <div
        class="ml-4 flex items-center justify-between text-black dark:text-white"
      >
        <div class="relative m-auto w-full items-center text-center">
          <button
            tabindex="0"
            data-cy="close-goal-modal"
            class="transformscale-y absolute right-4 top-[0.4375rem] flex w-full cursor-pointer flex-row justify-end text-11 text-white-lightest"
            @click.prevent="$emit('close-survey')"
            @keyup.enter.prevent="$emit('close-survey')"
            @keyup.space.prevent="$emit('close-survey')"
          >
            X
          </button>
          <div class="m-auto my-4 w-[6.5rem]">
            <cu-logo type="small"></cu-logo>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
