<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-up.js";
import "@cisco-u/icons/icons/cu-icon-chevron-down.js";

import { defineComponent } from "vue";

import loaderIcon from "@/components/loaderIcon.vue";

interface SortOpt {
  name: string;
  value: string;
}
export default defineComponent({
  emits: ["sort"],
  components: {
    loaderIcon,
  },
  props: {
    selectedSort: {
      type: String,
      default: "soonest",
    },
    showSoonest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: {
        name: "Latest",
        value: "soonest",
      },
      showOptions: false,
      isLoading: false,
    };
  },
  computed: {
    options(): SortOpt[] {
      let opts = [
        {
          name: "Latest",
          value: "soonest",
        },
        {
          name: "Duration",
          value: "duration",
        },
        {
          name: "A-Z",
          value: "alphabetical",
        },
      ];
      if (
        this.$route.path?.includes("/explore/learning-paths") ||
        this.$route.path?.includes("/explore/path")
      ) {
        opts.push({
          name: "CE Credits",
          value: "number_of_credits",
        });
      }
      if (this.showSoonest) {
        opts.forEach((option: SortOpt) => {
          if (option.name == "Latest") {
            option.name = "Soonest";
          }
        });
      }

      return opts;
    },
    selectedResultSet() {
      return (
        this.options.find((option: SortOpt) => {
          return option.value == this.selectedSort;
        })?.name ?? "Latest"
      );
    },
  },
  methods: {
    hideDropDown() {
      this.showOptions = false;
    },
    filters(option: SortOpt): void {
      this.selected = option;
      this.$emit("sort", option.value);
      this.hideDropDown();
    },
  },
});
</script>

<template>
  <div
    class="mt-3 flex cursor-pointer flex-nowrap text-14"
    v-click-outside="hideDropDown"
  >
    <span class="text-black-dark">Sort by:&nbsp;</span>
    <button
      data-cy="sort-filters-show"
      @click="showOptions = !showOptions"
      @keyup.enter="showOptions = !showOptions"
      @keyup.space="showOptions = !showOptions"
      class="flex text-black-dark"
    >
      {{ selectedResultSet }}
      <div class="p-1 pl-2 text-8 font-light text-black-lightest">
        <cu-icon-chevron-up v-if="showOptions"></cu-icon-chevron-up>
        <cu-icon-chevron-down v-else></cu-icon-chevron-down>
        <loaderIcon :is-loading="isLoading" />
      </div>
    </button>
    <div
      class="absolute z-20 mt-8 w-36 rounded-xl bg-white-lightest px-4 py-2 text-black-dark drop-shadow-2xl"
      v-show="showOptions"
    >
      <button
        data-cy="sort-filters-change"
        class="options z-10 flex w-full cursor-pointer border-b border-white-dark px-4 py-[.313rem] text-12 font-light last:border-0 hover:bg-blue-light hover:text-white-lightest focus:bg-blue-light"
        v-for="(option, index) in options"
        :key="index"
        @click="filters(option)"
        @keyup.enter="filters(option)"
        @keyup.space="filters(option)"
      >
        {{ option.name }}
      </button>
    </div>
  </div>
</template>
