<script lang="ts">
import "@cisco-u/new-card/cu-new-card.js";
import "@cisco-u/progress-bar/cu-progress-bar.js";
import "@cisco-u/reicon-lock/cu-reicon-lock.js";
import "@cisco-u/base-card/cu-base-card.js";

import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import newCard from "@/components/contentBits/newCard";
import tagList from "@/components/contentBits/tagList.vue";
import RawHtmlBlock from "@/components/RawHtmlBlock.vue";

import { progressCalc } from "../../utils/contentProgress";

export default defineComponent({
  emits: ["navigate", "bookmark", "ctaClick"],
  components: {
    RawHtmlBlock,
    tagList,
  },
  props: {
    isUnauth: {
      type: Boolean,
      default: false,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    result: {
      type: Object,
      required: true,
    },
    status: {
      type: Object,
      default: null,
    },
    cy: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInViewPort: false,
    };
  },
  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
      achievements: "user/achievements",
      prices: "pricing/prices",
      singleTitleBundles: "pricing/singleTitleBundles",
      freeId: "content/freeId",
    }),
    eventDate() {
      if (this.result?.type == "webinar") {
        return this.cardData.date;
      }
      return null;
    },
    authorName(): string {
      if (this.result?.author) {
        return this.result.author;
      }
      return "";
    },
    freeContent(): boolean {
      return this.result?.content_bundle?.includes(this.freeId);
    },
    displayName() {
      if (this.result?.name) {
        return `${this.result.name}`;
      }
      return this.result?.title ? this.result.title : "";
    },
    progressPercentage(): number {
      if (!this.result || this.isUnauth) return 0;

      const resultType = ["path", "tutorial", "course"].includes(
        this.result.type
      )
        ? this.result.type
        : "course";

      if (resultType === "tutorial") {
        return (
          this.progressCalc(this.result, this.achievements, resultType) ||
          this.progressCalc(this.result, this.achievements, "course")
        );
      }
      return this.progressCalc(this.result, this.achievements, resultType);
    },
    cardData() {
      return newCard.dataObject(this.result, this.status);
    },
  },
  mounted() {
    if (import.meta.env.TEST) {
      this.isInViewPort = true;
      this.applyStyles();
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isInViewPort = true;
          this.applyStyles();
          observer.disconnect();
        }
      });
    });
    observer.observe(this.$el);
  },
  methods: {
    progressCalc,
    applyStyles() {
      const cuNewCard = this.$el.querySelector("cu-new-card");

      if (
        (cuNewCard && !cuNewCard.shadowRoot) ||
        !this.result?.is_partial ||
        import.meta.env.TEST
      )
        return;

      let sheet = new CSSStyleSheet();
      sheet.insertRule(".footer{flex-wrap:wrap}");
      sheet.insertRule(
        ".footer > .updated-text{flex-basis:100%;display:flex;justify-content:flex-end}"
      );

      cuNewCard.shadowRoot.adoptedStyleSheets = [
        ...cuNewCard.shadowRoot.adoptedStyleSheets,
        sheet,
      ];
    },
  },
});
</script>

<template>
  <div data-cy="card-cu">
    <cu-new-card
      v-if="isInViewPort && !isUnauth"
      class="h-[22rem]"
      :type="cardData.type"
      :bookmarked="inMyList(result)"
      :progress="progressPercentage"
      :premium="freeContent ? !freeContent : cardData.premium"
      :vendor="cardData.vendor"
      :author="authorName"
      :date="eventDate"
      :list="cardView == 'Grid' ? false : true"
      :data-cy="cy"
      @cu-card.prevent="$emit('navigate', result)"
      @cu-card-bookmark.prevent="$emit('bookmark', result)"
      @cu-card-cta.prevent="$emit('ctaClick', result)"
    >
      <div slot="tags">
        <tag-list :content="result" />
      </div>
      <div slot="heading">
        <RawHtmlBlock :content="displayName" class="line-clamp-3" />
        <span v-if="cardData?.type == 'learning-path' && result?.acronym != ''">
          &nbsp;| {{ " " + result?.acronym }}
        </span>
      </div>
      <div slot="description">
        {{ result.summary }}
      </div>
      <div slot="garden">
        <cu-icon-garden
          v-for="garden in cardData.cardGarden"
          :key="garden.type"
          :type="garden.type"
          :text="garden.text"
          horizontal="true"
        >
        </cu-icon-garden>
      </div>
    </cu-new-card>
    <cu-new-card
      v-else-if="isInViewPort && isUnauth"
      class="h-[22rem]"
      :type="cardData.type"
      nobookmark=""
      :progress="progressPercentage"
      :premium="freeContent ? !freeContent : cardData.premium"
      :vendor="cardData.vendor"
      :author="authorName"
      :date="eventDate"
      :list="cardView == 'Grid' ? false : true"
      :data-cy="cy"
      @cu-card.prevent="$emit('navigate', result)"
      @cu-card-bookmark.prevent="$emit('bookmark', result)"
      @cu-card-cta.prevent="$emit('ctaClick', result)"
    >
      <div slot="tags">
        <tag-list :content="result" />
      </div>
      <div slot="heading">
        <RawHtmlBlock :content="displayName" class="inline" />
        <span v-if="cardData?.type == 'learning-path' && result?.acronym != ''">
          &nbsp;| {{ " " + result?.acronym }}
        </span>
      </div>
      <div slot="description">
        {{ result.summary }}
      </div>
      <div slot="garden">
        <cu-icon-garden
          v-for="garden in cardData.cardGarden"
          :key="garden.type"
          :type="garden.type"
          :text="garden.text"
          horizontal="true"
        >
        </cu-icon-garden>
      </div>
    </cu-new-card>
  </div>
</template>

<style scoped>
[type="time"] {
  margin-right: 0.5rem;
  border: none;
  padding: 0;
  line-height: normal;
  background: none;
}
</style>
