import { createApp } from "vue";

import App from "@/App.vue";
import populateApp from "@/env/app/app";
import setSessionId from "@/env/setSessionId";
import router from "@/router";

await setSessionId();

// Create the app
const app = createApp(App);
// setup app
populateApp(app);

// When the routes are ready, start the app
router.isReady().then(() => {
  app.mount("#app");
});
