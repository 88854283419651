<script lang="ts">
import { random } from "lodash-es";
import { defineComponent } from "vue";

import AnchorTargetBlank from "@/components/AnchorTargetBlank.vue";
import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  components: {
    AnchorTargetBlank,
  },
  computed: {
    background(): string {
      return !this.certification
        ? ["labs", "certquestions", "womenNetwork"][random(0, 2)]
        : "";
    },
  },
  props: {
    link: {
      type: String,
      default: "",
    },
    community: {
      type: String,
      default: "",
    },
    followers: {
      type: Number,
      default: 0,
    },
    posts: {
      type: Number,
      default: 0,
    },
    articles: {
      type: Number,
      default: 0,
    },
    certification: {
      type: Boolean,
      default: false,
    },
    myCommunities: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isUnauth,
    async sendUnauthTelemetry(community: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, community, true);
      }
    },
  },
});
</script>

<template>
  <div
    :class="[
      'group mb-4 flex w-full gap-x-6 lg:w-1/2 xl:w-1/3',
      {
        'lg:mb-[3.375rem]': certification && isUnauth(),
        'lg:mb-[2.853rem]': !certification && isUnauth(),
        'lg:mb-20': !isUnauth(),
      },
    ]"
  >
    <div
      v-if="!isUnauth() || (isUnauth() && certification)"
      data-cy="community-group-rounded-circle"
      :class="[
        'flex items-center justify-center rounded-full bg-blue-deep bg-center bg-no-repeat font-extralight text-white-lightest',
        {
          'h-[5.625rem] w-[5.625rem] text-[0.878rem]': isUnauth(),
          'h-[9.375rem] min-h-[8.15rem] w-[9.375rem] min-w-[8.15rem]':
            !isUnauth(),
          'bg-[url(@/views/CommunityLanding/assets/labs.svg)]':
            background == 'labs',
          'bg-[url(@/views/CommunityLanding/assets/certquestions.png)]':
            background == 'certquestions',
          'bg-[url(@/views/CommunityLanding/assets/womenNetwork.png)]':
            background == 'womenNetwork',
          'text-29': community.length <= 6,
          'text-18': community.length > 6 && community.length <= 13,
          'text-16': community.length > 13,
          'max-h-[8.15rem] max-w-[8.15rem]': certification === false,
        },
      ]"
    >
      <span v-if="certification" class="text-center">
        {{ community }}
      </span>
    </div>
    <div class="content-center">
      <AnchorTargetBlank
        @click="sendUnauthTelemetry(community)"
        :url="link"
        :class="[
          {
            'text-[1.073rem]': isUnauth(),
            'text-22': !isUnauth(),
          },
          'text-blue-light group-hover:underline',
        ]"
      >
        {{ community }}
      </AnchorTargetBlank>
      <span
        v-if="!isUnauth() && myCommunities.length"
        class="icon-Chat-Checkmark ml-2 align-middle text-22"
      />

      <div class="flex flex-wrap sm:flex-nowrap">
        <div data-cy="community-group-followers" class="mr-6">
          <p :class="[{ 'text-[0.683rem]': isUnauth() }, 'font-semibold']">
            {{
              new Intl.NumberFormat("en-US", {
                style: "decimal",
              }).format(followers)
            }}
          </p>
          <p
            :class="[
              {
                'text-[0.585rem]': isUnauth(),
                'text-12': !isUnauth(),
              },
              'text-black-lightest',
            ]"
          >
            Followers
          </p>
        </div>
        <div data-cy="community-group-posts" class="mr-6">
          <p :class="[{ 'text-[0.683rem]': isUnauth() }, 'font-semibold']">
            {{
              new Intl.NumberFormat("en-US", {
                style: "decimal",
              }).format(posts)
            }}
          </p>
          <p
            :class="[
              {
                'text-[0.585rem]': isUnauth(),
                'text-12': !isUnauth(),
              },
              'text-black-lightest',
            ]"
          >
            Posts
          </p>
        </div>
        <div data-cy="community-group-articles" class="mr-6">
          <p :class="[{ 'text-[0.683rem]': isUnauth() }, 'font-semibold']">
            {{
              new Intl.NumberFormat("en-US", {
                style: "decimal",
              }).format(articles)
            }}
          </p>
          <p
            :class="[
              {
                'text-[0.585rem]': isUnauth(),
                'text-12': !isUnauth(),
              },
              'text-black-lightest',
            ]"
          >
            Articles
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
