<script lang="ts">
import { defineComponent } from "vue";

import AnchorTargetBlank from "@/components/AnchorTargetBlank.vue";
import RawHtmlBlock from "@/components/RawHtmlBlock.vue";
import middlewareService from "@/services/middlewareService";
import isUnauth from "@/utils/isUnauth";
import openWindow from "@/utils/openWindow";
import StripHtml from "@/utils/StripHtml";

export default defineComponent({
  components: {
    AnchorTargetBlank,
    RawHtmlBlock,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    numposts: {
      type: Number,
      default: 2,
    },
    hideBtn: {
      type: Boolean,
      default: true,
    },
    clnUser: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      threads: [] as any,
      link: "",
      community: "",
      followers: 0,
      posts: 0,
      questions: 0,
      certification: false,
    };
  },
  computed: {
    strippedContent() {
      return this.threads.map((item: any) => {
        item.Body = StripHtml(item.Body);
        return item;
      });
    },
  },
  mounted() {
    this.fetchDiscussions();
  },
  methods: {
    isUnauth,
    openWindow,
    fetchDiscussions() {
      if (this.id) {
        middlewareService.DiscussionThreads.fetch(this.id, this.numposts)
          .then((data) => {
            this.threads = data[0].Threads;
            this.community = data[0].Name;
          })
          .catch(() => {
            // do nothing
          });
      }
    },
  },
});
</script>

<template>
  <div data-cy="mycommunity-thread" class="container">
    <div v-if="threads.length > 0">
      <div>
        <p
          data-cy="mycommunity-name"
          :class="[
            isUnauth()
              ? 'text-[0.468rem] font-light leading-[0.562rem] tracking-[0.14rem]'
              : 'mb-[.5625rem] pb-2.5 pt-[0.3125rem] text-12 leading-4 tracking-[.3em]',
            'font-light',
            'text-black-lightest',
            { 'text-white': darkMode },
          ]"
        >
          {{ isUnauth() ? community.toUpperCase() : community }}
        </p>
      </div>
      <div :class="[columns ? 'lg:columns-2' : '']">
        <div
          class="mr-10 max-w-[34.625rem] flex-col md:mr-[8.9375rem]"
          v-for="thread in strippedContent"
          :key="thread.Id"
        >
          <div class="flex">
            <div>
              <h3
                :class="[
                  isUnauth()
                    ? 'mb-2.5 mt-[0.938rem] text-18 font-lighter leading-[1.35rem] text-black-dark'
                    : 'mb-[1.1875rem] min-h-[2.15rem]  text-[1.03125rem] font-light leading-[1.2375rem] text-black-darkest',
                  'ellipsis2 max-w-[34.625rem] -tracking-[0.02em]',
                  { 'text-white': darkMode },
                ]"
              >
                {{ thread.Title }}
              </h3>
            </div>
          </div>
          <RawHtmlBlock
            :class="[
              'ellipsis2 text-sm font-light leading-[1.225rem] text-black-lightest',
              { 'text-white': darkMode, 'mb-[.9375rem]': !isUnauth() },
            ]"
            :content="thread.Body"
          />
          <cu-buttons
            v-if="isUnauth()"
            data-cy="discussion-btn"
            @click="() => openWindow(thread.URL, '_blank', 'noopener')"
            @keyup.enter="() => openWindow(thread.URL, '_blank', 'noopener')"
            @keyup.space="() => openWindow(thread.URL, '_blank', 'noopener')"
            class="mt-3"
            value="Learn More"
            btntype="primary"
            size="sm"
          >
          </cu-buttons>
          <div v-else>
            <AnchorTargetBlank
              v-if="hideBtn"
              data-cy="spotlight-awards-btn"
              :url="thread.URL"
              class="btn mb-9 inline-flex items-center border-none bg-blue-light p-4 text-sm text-white-lightest dark:text-black-lightest"
              >View Discussion
            </AnchorTargetBlank>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
