import { getUpcomingClasses } from "@/services/instanceService";

export default {
  namespaced: true,
  state: {
    upcomingClasses: [],
  },
  getters: {
    upcomingClasses: (state: any) => state.upcomingClasses,
  },
  mutations: {
    setUpcomingClasses(state: any, value: any) {
      state.upcomingClasses = value;
    },
  },
  actions: {
    fetchUpcomingClasses({ commit }: any) {
      getUpcomingClasses()
        .then((classes) => {
          commit("setUpcomingClasses", classes);
        })
        .catch(() => {
          // do nothing
        });
    },
  },
};
