<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showFeedback: false,
    };
  },
});
</script>

<template>
  <div>
    <div class="fixed right-0 top-1/2 z-[9999999] h-28 w-28">
      <button
        class="btn -rotate-90 bg-blue-light text-white-lightest"
        @click.prevent="showFeedback = !showFeedback"
        @keyup.enter.prevent="showFeedback = !showFeedback"
        @keyup.space.prevent="showFeedback = !showFeedback"
      >
        Feedback
      </button>
    </div>
    <div
      class="fixed bottom-0 left-0 right-0 top-0 z-[9999999] h-screen w-screen bg-black bg-opacity-50"
      v-if="showFeedback"
      @click.prevent="showFeedback = false"
      @keyup.enter.prevent="showFeedback = false"
    >
      <div
        class="mx-auto my-40 flex w-[90%] items-center rounded-xl border border-gray bg-white-lightest p-4"
      >
        <iframe :src="url" width="100%" height="600px" title="survey"></iframe>
      </div>
    </div>
  </div>
</template>
