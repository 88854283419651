<script lang="ts">
import "@cisco-u/marquee-card/cu-marquee-card.js";

import { startCase } from "lodash-es";
import { defineComponent } from "vue";

import RawHtmlBlock from "@/components/RawHtmlBlock.vue";
import middlewareService from "@/services/middlewareService";
import { EventBus } from "@/store/eventBus";
import StripHtml from "@/utils/StripHtml";
import telemetry from "@/utils/telemetry";

interface CustomField {
  enum_value?: any;
  text_value?: string | null;
  gid: string;
  name: string;
}

interface MarqueeData {
  custom_fields: CustomField[];
  gid: string;
  memberships: [
    {
      section: {
        gid: string;
        name: string;
      };
    }
  ];
  name: string;
}

export default defineComponent({
  components: {
    RawHtmlBlock,
  },
  emits: ["openGoSurveyModal"],
  props: {
    status: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      surveyCompleted: false,
      marqueeOne: {
        typeName: "recommend-update",
        title: "Recommendations",
        heading: "Cisco U. evolves <br />as you do",
        description:
          "Revisit your interests to see <strong>certifications</strong> and <strong>training</strong> that meet your current goals.",
        primaryButton: {
          type: "primary",
          text: "Update interests",
          width: "10.5rem",
        },
      },
      marqueeOneAlt: {
        typeName: "recommend-update",
        title: "Recommendations",
        heading: "Tell us about your Interests",
        description:
          "To unlock Cisco U. recommendations. Tell us more about your goals!",
        primaryButton: {
          type: "secondary",
          text: "Take survey",
          width: "10rem",
        },
      },
      marqueeTwo: {} as any,
      marqueeThree: {} as any,
    };
  },
  computed: {
    isCommunity() {
      const communityRoutes = [
        "community",
        "my-list",
        "dashboard",
        "my-account",
      ];
      return (
        this.$route && communityRoutes.includes(this.$route.name as string)
      );
    },
    getUser() {
      return this.status?.surveyCompleted ?? false;
    },
    loading() {
      return !(
        Object.keys(this.marqueeTwo).length > 0 &&
        Object.keys(this.marqueeThree).length > 0
      );
    },
  },

  watch: {
    status(newVal, oldVal) {
      if (newVal) this.fetchMarquees();
    },
  },

  mounted() {
    EventBus.on("updatesurveyCompleted", (data: boolean) => {
      this.surveyCompleted = data;
    });
    if (this.status) this.fetchMarquees();
  },
  methods: {
    fetchMarquees() {
      middlewareService.Marquees.fetch()
        .then(({ data }) => {
          const currentEnvMarquees = this.pullEnvMarquees(data);
          currentEnvMarquees.forEach((task: any) => {
            this.fillMarqueeData(task);
          });
        })
        .catch(() => {
          // intentional
        });
    },
    pullEnvMarquees(data: any) {
      const cuEnv = startCase(window.env.CISCO_U_ENVIRONMENT?.toLowerCase());
      const sectionName = cuEnv == "Prod" ? "Production" : cuEnv;
      return (
        data.filter(
          (marquee: MarqueeData) =>
            marquee.memberships[0].section.name === sectionName
        ) ?? []
      );
    },
    returnFieldData(customFields: any, fieldName: string) {
      return customFields.find((field: any) => field.name == fieldName);
    },
    checkFieldValue(fieldValue: any) {
      return !!(fieldValue && (fieldValue.text_value || fieldValue.enum_value));
    },
    returnMarqueeButton(buttonType: any, buttonText: any) {
      return {
        type:
          buttonType.enum_value.name === "dark"
            ? "primary"
            : buttonType.enum_value.name,
        text: buttonText.text_value,
        dark: buttonType.enum_value.name === "dark" ? true : null,
      };
    },
    createMarqueeObj(customFields: any) {
      const marqueeObj: any = {};

      const marqueeType = this.returnFieldData(customFields, "Type");
      const marqueeTitle = this.returnFieldData(customFields, "Title");
      const marqueeHeading = this.returnFieldData(customFields, "Heading");
      const marqueeDescription = this.returnFieldData(
        customFields,
        "Marquee Description"
      );
      const marqueeLocation = this.returnFieldData(customFields, "Location");
      const marqueePrimaryType = this.returnFieldData(
        customFields,
        "Primary Type"
      );
      const marqueePrimaryText = this.returnFieldData(
        customFields,
        "Primary Text"
      );
      const marqueeSecondaryType = this.returnFieldData(
        customFields,
        "Secondary Type"
      );
      const marqueeSecondaryText = this.returnFieldData(
        customFields,
        "Secondary Text"
      );
      const marqueeUrl = this.returnFieldData(customFields, "URL");
      const marqueeNewTab = this.returnFieldData(customFields, "New Tab");

      if (this.checkFieldValue(marqueeType))
        marqueeObj["typeName"] = marqueeType.enum_value.name;

      if (this.checkFieldValue(marqueeUrl))
        marqueeObj["url"] = marqueeUrl.text_value;

      if (this.checkFieldValue(marqueeNewTab))
        marqueeObj["newTab"] = marqueeNewTab.enum_value.name;

      if (this.checkFieldValue(marqueeTitle))
        marqueeObj["title"] = marqueeTitle.text_value;

      if (this.checkFieldValue(marqueeHeading))
        marqueeObj["heading"] = marqueeHeading.text_value;

      if (this.checkFieldValue(marqueeDescription))
        marqueeObj["description"] = marqueeDescription.text_value;

      if (this.checkFieldValue(marqueeLocation))
        marqueeObj["location"] = marqueeLocation.text_value;

      if (
        this.checkFieldValue(marqueePrimaryType) &&
        this.checkFieldValue(marqueePrimaryText)
      ) {
        marqueeObj.primaryButton = this.returnMarqueeButton(
          marqueePrimaryType,
          marqueePrimaryText
        );
      }

      if (
        this.checkFieldValue(marqueeSecondaryType) &&
        this.checkFieldValue(marqueeSecondaryText)
      ) {
        marqueeObj.secondaryButton = this.returnMarqueeButton(
          marqueeSecondaryType,
          marqueeSecondaryText
        );
      }

      return marqueeObj;
    },
    fillMarqueeData(task: any) {
      const fields = task.custom_fields;
      if (task.name == "Marquee Middle") {
        this.marqueeTwo = this.createMarqueeObj(fields);
      }
      if (task.name == "Marquee End") {
        this.marqueeThree = this.createMarqueeObj(fields);
      }
    },
    updateSurveyCompleteStatus() {
      this.surveyCompleted = true;
    },
    marquee1Link() {
      if (this.getUser || this.surveyCompleted) {
        this.sendTelemetry(
          window.location.href,
          {
            type: "cardDetails",
            typeName: this.marqueeOne.typeName,
            heading: this.stripHTML(this.marqueeOne.heading),
            description: this.stripHTML(this.marqueeOne.description),
          },
          this.marqueeOne.heading
        );
      } else {
        this.sendTelemetry(
          window.location.href,
          {
            type: "cardDetails",
            typeName: this.marqueeOneAlt.typeName,
            heading: this.stripHTML(this.marqueeOneAlt.heading),
            description: this.stripHTML(this.marqueeOneAlt.description),
          },
          this.marqueeOneAlt.heading
        );
      }
      this.$emit("openGoSurveyModal");
    },
    marquee2Link() {
      this.sendTelemetry(
        this.marqueeTwo.url,
        {
          type: "cardDetails",
          typeName: this.marqueeTwo.typeName,
          heading: this.marqueeTwo.heading
            ? this.stripHTML(this.marqueeTwo.heading)
            : "",
          description: this.marqueeTwo.description
            ? this.stripHTML(this.marqueeTwo.description)
            : "",
          title: this.marqueeTwo.title,
          location: this.marqueeTwo.location,
        },
        this.marqueeTwo.heading
      );

      if (this.marqueeTwo.newTab === "True")
        window.open(this.marqueeTwo.url, "_blank", "noopener");
      else window.location.href = this.marqueeTwo.url;
    },
    marquee3Link() {
      this.sendTelemetry(
        this.marqueeThree.url,
        {
          type: "cardDetails",
          typeName: this.marqueeThree.typeName,
          heading: this.marqueeThree.heading
            ? this.stripHTML(this.marqueeThree.heading)
            : "",
          description: this.marqueeThree.description
            ? this.stripHTML(this.marqueeThree.description)
            : "",
          title: this.marqueeThree.title,
          location: this.marqueeThree.location,
        },
        this.marqueeThree.heading
      );

      if (this.marqueeThree.newTab === "True")
        window.open(this.marqueeThree.url, "_blank", "noopener");
      else window.location.href = this.marqueeThree.url;
    },
    sendTelemetry(url: string, marqueeObj: any, objTitle: string) {
      telemetry.marqueeCardDetails(marqueeObj, this.stripHTML(objTitle), url);
    },
    stripHTML(text: string) {
      return StripHtml(text);
    },
  },
});
</script>

<template>
  <div
    data-cy="marquee-top"
    ref="marquee-top"
    :class="[
      'mx-auto mt-10 flex w-full flex-col items-center justify-center gap-4 md:mt-0 md:flex-row md:flex-wrap lg:w-[80rem]',
      { 'mt-14': isCommunity, 'mt-1': !isCommunity },
    ]"
  >
    <div id="join-usy">
      <div v-if="getUser || surveyCompleted">
        <cu-marquee-card
          data-cy="marquee-card-1"
          :type="marqueeOne.typeName"
          :cardtitle="marqueeOne.title"
          position="first"
          @marquee-primary="marquee1Link"
          :primarybutton="JSON.stringify(marqueeOne.primaryButton)"
        >
          <RawHtmlBlock slot="heading" :content="marqueeOne.heading" />

          <RawHtmlBlock slot="description" :content="marqueeOne.description" />
        </cu-marquee-card>
      </div>
      <div v-else>
        <cu-marquee-card
          data-cy="marquee-card-1"
          :type="marqueeOneAlt.typeName"
          :cardtitle="marqueeOneAlt.title"
          position="first"
          @marquee-primary="marquee1Link"
          :primarybutton="JSON.stringify(marqueeOneAlt.primaryButton)"
        >
          <RawHtmlBlock slot="heading" :content="marqueeOneAlt.heading" />

          <RawHtmlBlock
            slot="description"
            :content="marqueeOneAlt.description"
          />
        </cu-marquee-card>
      </div>
    </div>
    <div v-if="loading">
      <div
        class="flex h-64 w-[21.5rem] flex-col items-center justify-center bg-gray-medium px-4 font-cisco text-white"
      >
        <p class="text-center text-xl">Loading</p>
        <div class="mt-8 flex gap-1">
          <span class="dot dot-1"></span><span class="dot dot-2"></span
          ><span class="dot dot-3"></span><span class="dot dot-4"></span>
        </div>
      </div>
    </div>
    <div class="relative" v-else>
      <cu-marquee-card
        data-cy="marquee-card-2"
        :type="marqueeTwo.typeName"
        :cardtitle="marqueeTwo.title"
        headingtype="h1"
        @marquee-primary="marquee2Link"
        :location="marqueeTwo.location ? marqueeTwo.location : null"
        :primarybutton="JSON.stringify(marqueeTwo.primaryButton)"
        :secondaryButton="
          marqueeTwo.secondaryButton
            ? JSON.stringify(marqueeTwo.secondaryButton)
            : null
        "
      >
        <RawHtmlBlock
          v-show="marqueeTwo.heading"
          slot="heading"
          :content="marqueeTwo.heading"
        />

        <RawHtmlBlock
          v-show="marqueeTwo.description"
          slot="description"
          :content="marqueeTwo.description"
        />
      </cu-marquee-card>
    </div>
    <div v-if="loading">
      <div
        class="flex h-64 w-[21.5rem] flex-col items-center justify-center bg-gray-medium px-4 font-cisco text-white"
      >
        <p class="text-center text-xl">Loading</p>
        <div class="mt-8 flex gap-1">
          <span class="dot dot-1"></span><span class="dot dot-2"></span
          ><span class="dot dot-3"></span><span class="dot dot-4"></span>
        </div>
      </div>
    </div>
    <cu-marquee-card
      v-else
      data-cy="marquee-card-3"
      :type="marqueeThree.typeName"
      :cardtitle="marqueeThree.title ? marqueeThree.title : null"
      position="last"
      @marquee-primary="marquee3Link"
      :location="marqueeThree.location ? marqueeThree.location : null"
      :primarybutton="JSON.stringify(marqueeThree.primaryButton)"
      :secondaryButton="
        marqueeThree.secondaryButton
          ? JSON.stringify(marqueeThree.secondaryButton)
          : null
      "
    >
      <RawHtmlBlock
        v-show="marqueeThree.heading"
        slot="heading"
        :content="marqueeThree.heading"
      />

      <RawHtmlBlock
        v-show="marqueeThree.description"
        slot="description"
        :content="marqueeThree.description"
      />
    </cu-marquee-card>
  </div>
</template>

<style scoped>
.dot {
  width: 0.75rem;
  height: 0.75rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1s infinite ease-in-out;
  margin: 0 4px;
}

.dot-1 {
  animation-delay: 0;
}

.dot-2 {
  animation-delay: 0.2s;
}

.dot-3 {
  animation-delay: 0.4s;
}

.dot-4 {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
