<script lang="ts">
import DOMPurify from "dompurify";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
 
export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    sanitizedHtml(value: string): string {
      return DOMPurify.sanitize(value, { ADD_ATTR: ["target"] });
    },
  },
  computed: {
    ...mapGetters({
      chunkResults: 'genAi/chunkResults',
    }),
  },
});
</script>

<template>
  <div v-html="sanitizedHtml(content)"></div>
</template>