<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { LocationQuery } from "vue-router";
import { mapGetters } from "vuex";

import CertCard from "@/components/contentBits/certCard.vue";
import challengeResult from "@/components/contentBits/challengeResult.vue";
import EventResult from "@/components/contentBits/eventsResult.vue";
import InstructorLedResult from "@/components/contentBits/instructorLedResult.vue";
import LearningPathResult from "@/components/contentBits/learningPathResult.vue";
import PodCastCard from "@/components/contentBits/podCastCard.vue";
import PracticeExamResult from "@/components/contentBits/practiceExamResult.vue";
import TutorialResult from "@/components/contentBits/tutorialsResult.vue";
import VideoCard from "@/components/contentBits/videosCard.vue";
import searchService from "@/services/searchService";
import type { SearchResponse } from "@/services/searchService/types";
import flattenSearchResult from "@/utils/flattenSearchResult";
import isUnauth from "@/utils/isUnauth";
import type { FacetFilter } from "@/views/Search/SearchTypes";
import SkeletonLoader from "@/views/Skeleton/Search/SkeletonLoader.vue";

interface PageConfig {
  current: number;
  size: number;
}

export default defineComponent({
  emits: ["see-more"],
  components: {
    CertCard,
    challengeResult,
    PodCastCard,
    LearningPathResult,
    EventResult,
    VideoCard,
    SkeletonLoader,
    TutorialResult,
    InstructorLedResult,
    PracticeExamResult,
  },
  props: {
    typeName: {
      type: String,
      default: "",
    },
    page: {
      type: Object as PropType<PageConfig>,
      default: () => ({
        current: 1,
        size: 6,
      }),
    },
    contentType: {
      type: String,
      default: "",
    },
    results_shown: {
      type: Number,
      default: 3,
    },
    resultComponent: {
      type: String,
      required: true,
    },
    topCourses: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      facetFilters: [] as Array<FacetFilter>,
      isSearching: false,
      isVisible: false,
      hasSearched: false,
      transitionKey: 0,
      response: null as SearchResponse | null,
    };
  },
  computed: {
    ...mapGetters({
      status: "user/status",
    }),
    results() {
      return this.response?.results ?? [];
    },
    requestId() {
      return this.response?.meta?.request_id ?? 0;
    },
    limitedResults() {
      return this.results.slice(0, this.results_shown);
    },
    gap() {
      const length = this.typeName.length;
      const lengthMap = {
        15: "gap-x-96",
        16: "gap-x-[22rem]",
        17: "gap-x-80",
        18: "gap-x-[23rem]",
        21: "gap-x-60",
        23: "gap-x-56",
      };
      return lengthMap[length as keyof typeof lengthMap] ?? "gap-x-64";
    },
    showTitle() {
      return this.results.length > 0 && this.limitedResults.length > 0;
    },
  },
  watch: {
    // eslint-disable-next-line sonarjs/no-identical-functions
    page() {
      this.fetchSearchResults();
    },
    $route() {
      this.hasSearched = false;
      this.fetchSearchResults();
    },
    isVisible() {
      this.fetchSearchResults();
    },
  },
  mounted() {
    if (this.topCourses) {
      this.fetchTopCourses();
    } else {
      this.observeElement();
    }
  },
  methods: {
    isUnauth,
    observeElement() {
      // listen for the element to enter the viewport to fetch search results
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          // @ts-ignore
          this.isVisible = entries[0].isIntersecting;
        },
        {
          rootMargin: "0px 0px 0px 0px",
          threshold: 0.05,
        }
      );
      intersectionObserver.observe(this.$refs.searchSection as HTMLElement);
    },
    flattenSearchResult,
    trackClick(result: any) {
      searchService.trackClickThrough.trackClickThrough(
        {
          request_id: this.requestId,
          result: result,
          tenant: ["apollo"],
          query: this.$route?.query?.query,
          score: result._meta.score,
          page: JSON.parse(JSON.stringify(this.page)),
          url: window.location.href,
        },
        this.isUnauth()
      );
    },
    fetchTopCourses() {
      searchService
        .fetchTypeResults(
          {
            query: "",
            page: {
              current: 1,
              size: 6,
            },
            type: "course",
          },
          {} as LocationQuery,
          this.isUnauth()
        )
        .then((data) => {
          this.isSearching = false;
          this.hasSearched = true;
          this.response = data as SearchResponse;
        })
        .catch(() => {
          this.isSearching = false;
          this.hasSearched = true;
        });
    },
    fetchSearchResults() {
      if (
        !this.contentType ||
        this.contentType == "" ||
        !this.isVisible ||
        this.hasSearched
      ) {
        return;
      }

      this.isSearching = true;
      this.hasSearched = false;

      searchService
        .fetchTypeResults(
          {
            query: (this.$route?.query?.query as string) ?? "",
            page: this.page as any,
            type: this.contentType,
          },
          this.$route.query,
          this.isUnauth()
        )

        .then(
          // eslint-disable-next-line sonarjs/no-identical-functions
          (data) => {
            this.isSearching = false;
            this.hasSearched = true;
            this.response = data as SearchResponse;
          }
        )
        .catch(() => {
          this.isSearching = false;
          this.hasSearched = true;
        });
    },
  },
});
</script>

<template>
  <div class="my-8 min-h-[19.625rem] w-full" ref="searchSection">
    <div :class="['mb-4 flex flex-wrap content-around justify-between', gap]">
      <div
        class="order-[0] flex-none grow-0 text-29 font-light leading-[120%] text-black-dark"
      >
        <slot />
        <strong v-if="showTitle" class="text-29"> {{ typeName }}</strong>
      </div>
      <div class="flex flex-wrap gap-4">
        <button
          tabindex="0"
          data-cy="search-explore-see-more"
          v-if="
            hasSearched &&
            results.length &&
            limitedResults.length < results.length
          "
          class="order-[0] mr-4 flex-none grow-0 cursor-pointer text-right text-16 leading-[140%] text-blue-light"
          @click="$emit('see-more')"
          @keyup.enter="$emit('see-more')"
          @keyup.space="$emit('see-more')"
        >
          See More {{ typeName }} &#10132;
        </button>
      </div>
    </div>

    <skeleton-loader :limit="results_shown" v-if="isSearching" />
    <div v-else class="flex flex-wrap gap-4">
      <transition-group name="list" appear :key="transitionKey">
        <div
          v-for="(result, index) in limitedResults"
          :key="index"
          class="hover:z-[700] focus:z-[700] active:z-[700]"
        >
          <component
            :result="flattenSearchResult(result)"
            :is="resultComponent"
            :status="status"
            tabindex="0"
            @click="trackClick(result)"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>
