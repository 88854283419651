<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-cart-thick.js";
import "@cisco-u/text-badge/cu-text-badge.js";

import { defineComponent } from "vue";

import AiPromptBtn from "@/components/AiPromptBtn.vue";
import ProfileIcon from "@/layout/Header/dropDown.vue";
import PageNav from "@/layout/Header/PageNav.vue";
import SearchBar from "@/layout/Header/searchBar.vue";
import router from "@/router";
import { EventBus } from "@/store/eventBus";

export default defineComponent({
  emits: ["update-query"],
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    scrolled: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    instance: {
      type: String,
      default: "",
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cartItems: 0,
    };
  },
  components: {
    SearchBar,
    PageNav,
    ProfileIcon,
    AiPromptBtn,
  },
  mounted() {
    EventBus.on("updateQty", (qty: number) => {
      this.cartItems = qty;
    });
  },
  computed: {
    showGenAi() {
      return window.env.SHOW_GEN_AI;
    },
    showProfile() {
      if (
        this.$route &&
        this.$route.meta &&
        this.$route.meta.scrollShowProfile
      ) {
        return true;
      }
      return this.scrolled;
    },
    showNav() {
      return this.$route && this.$route.meta && this.$route.meta.hideMarquee;
    },
    noRounding(): boolean {
      return (this.$route &&
        this.$route.meta &&
        this.$route.meta.pageHeader &&
        this.$route.meta.hideMarquee) as boolean;
    },
    isExploreSubPages() {
      return ["/explore/", "/podcast/"].includes(this.$route.path);
    },
  },
  methods: {
    goToCart() {
      if (window.location.pathname == "/purchase") {
        EventBus.emit("showCart");
      } else {
        router.push({ name: "purchase" });
      }
    },
    updateQuery(newValue: any) {
      this.$emit("update-query", newValue);
    },
  },
});
</script>

<template>
  <div
    :data-cy="`page-header-${instance}`"
    :class="[
      'z-[1000]',
      {
        'rounded bg-black-dark': dark,
        'bg-white-lightest pt-0 dark:bg-black-lightest': !dark,
        'left-0 right-0 top-0 lg:fixed': scrolled || noRounding,
        'rounded-t-40': !scrolled && !noRounding,
      },
    ]"
  >
    <router-link
      v-if="dark || showLogo"
      to="/search/tutorial?query=AI%20chatgpt"
      class="hidden bg-blue-dark px-6 py-4 text-center text-22 leading-[1.2] -tracking-[0.0275rem] text-white-lightest hover:underline lg:block"
      active-class="underline"
      ><strong class="font-thicker"
        >Free AI training and tutorials from Cisco U.</strong
      >
      From entry to expert, gain real-world skills to succeed in
      AI.</router-link
    >

    <div
      :class="[
        'container hidden flex-row items-center justify-between pb-6 font-light lg:flex lg:px-4',
        {
          'pt-4 text-22 text-white-lightest lg:pt-10': dark,
          'text-16 lg:pt-8 lg:text-22': !dark,
          'mx-auto': !isExploreSubPages,
          'mr-4 lg:mx-4 lg:ml-[3.188rem]': isExploreSubPages,
        },
      ]"
    >
      <page-nav
        :dark="dark"
        :show-logo="showLogo || noRounding"
        v-if="showLogo || showNav"
        nav-for-you="for-you-page-nav"
        nav-explore="explore-page-nav"
        nav-community="community-page-nav"
      />
      <div class="hidden items-center justify-between lg:flex lg:flex-row">
        <search-bar
          :dark="dark"
          :show-logo="showLogo || noRounding"
          :instance="instance"
          nav-search="search-box"
          search-btn="search"
          :searchQuery="searchQuery"
          @update-query="updateQuery"
          v-if="showLogo || showNav"
        />
        <ai-prompt-btn
          v-if="showGenAi && (showLogo || showNav)"
          class="ml-4 xl:ml-10"
        />
        <profile-icon
          class="ml-4 block xl:ml-10"
          :scrolled="showProfile"
          :show-pic="showProfile"
          :dark="dark"
          profile-dropdown="toggle-dropdown"
          v-if="showLogo || showNav"
        />
        <div
          v-if="showLogo || showNav"
          class="ml-4 flex cursor-pointer flex-col items-center pb-2.5 xl:ml-10"
        >
          <cu-text-badge
            v-if="cartItems > 0"
            class="relative left-[0.1rem] top-[0.79rem] z-10 cursor-pointer"
            :text="cartItems"
            backgroundColor="base-red-secondary"
            color="base-white"
            data-cy="user-cart-item-badge"
            medium="true"
            @click="goToCart"
          />
          <cu-icon-cart-thick
            @click="goToCart"
            size="xl"
            data-cy="user-cart-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>
