<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-search.js";

import { defineComponent } from "vue";

import telemetry from "@/utils/telemetry";
// the search pages. technically also Search but that's a parent for these two
const searchAll = "search-all";
const searchType = "search-type";

export default defineComponent({
  emits: ["update-query"],
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    instance: {
      type: String,
      default: "",
    },
    navSearch: {
      type: String,
      default: "",
    },
    searchBtn: {
      type: String,
      default: "",
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      query: this.searchQuery,
      errorMounted: "",
    };
  },
  watch: {
    $route() {
      this.queryParamSetup();
    },
    searchQuery(newQuery) {
      this.query = newQuery;
    },
    query(newLocalQuery) {
      this.$emit("update-query", newLocalQuery);
    },
  },
  mounted() {
    this.queryParamSetup();
    if (this.$route.name === "error") {
      this.errorMounted = this.$route.fullPath;
    }
  },
  computed: {
    isSearchRoute(): boolean {
      return (
        this.$route &&
        [searchAll, searchType].includes(this.$route.name as string)
      );
    },
  },
  methods: {
    queryParamSetup() {
      if (this.isSearchRoute && this.$route.query && this.$route.query.query) {
        this.query = this.$route.query.query as string;
        if (this.errorMounted !== "") {
          telemetry.custom404("learner-navPath", this.errorMounted, "Search");
        }
      }
    },

    startSearching() {
      if (this.query && this.query.length >= 2) {
        this.redirectToSearch();
      }
    },
    redirectToSearch() {
      this.$router.push({ name: searchAll, query: { query: this.query } });
    },
  },
});
</script>

<template>
  <div
    :data-cy="`search-${instance}`"
    class="relative h-[3.525rem] shrink grow-0 rounded-full shadow md:flex lg:ml-10 xl:ml-20"
  >
    <div
      :class="[
        {
          'bg-black-dark text-white-lightest': !dark,

          'bg-white-lightest text-black-dark dark:bg-black-lightest dark:text-white-dark':
            dark,
        },
        'search-filter w-12 rounded-l-full pt-4 text-center text-18',
      ]"
    />
    <div :class="{ 'bg-black': dark }">
      <label :for="`searchHeader-${instance}`" class="border-0">
        <span class="sr-only">What would you like to learn?</span>
        <input
          :id="`searchHeader-${instance}`"
          :class="[
            'h-full w-full border-none py-4 pl-2 font-thin focus:ring-0 md:max-w-[27.125rem] lg:max-w-28 lg:text-lg xl:max-w-[30rem] xl:text-xl',
            { 'bg-black text-white': dark, 'dark:bg-black-lightest': !dark },
          ]"
          placeholder="What would you like to learn?"
          :data-cy="!dark ? navSearch : `${navSearch}-dark`"
          @keyup.enter.prevent="startSearching"
          v-model="query"
          autocomplete="off"
        />
      </label>
    </div>
    <button
      tabindex="0"
      :data-cy="!dark ? searchBtn : `${searchBtn}-dark`"
      :class="[
        'pointer-events-none cursor-pointer rounded-r-full no-underline focus:outline-none focus:ring-1',
        {
          'bg-black text-white-lightest focus-visible:ring-white-lightest':
            dark,
          'focus-visible:ring-black-dark': !dark,
        },
      ]"
      @click="startSearching"
      @keyup.enter="startSearching"
    >
      <div class="sr-hidden pl-4 pr-6">
        <cu-icon-search size="xl" class="text-white-dark"></cu-icon-search>
      </div>
      <span class="sr-only">Search</span>
    </button>
  </div>
</template>

<style scoped>
@media only screen and (min-width: 1536px) {
  ::placeholder {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  ::placeholder {
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  ::placeholder {
    font-size: 0.47rem;
  }
}

/* for cisco-u components */
[type="search"] {
  background: transparent;
  border-width: 0;
  padding: 0;
}
</style>
