<script lang="ts">
import "@cisco-u/buttons/cu-buttons.js";
import "@cisco-u/circular-buttons/cu-circular-buttons.js";
import "@cisco-u/heading/cu-heading.js";
import "@cisco-u/modal/cu-modal.js";

import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    openCdlModal() {
      const modal: any = document.querySelector("#cdl-modal");
      if (modal) modal?.openModal();
    },
  },
});
</script>

<template>
  <p>
    <button
      @click.prevent="openCdlModal"
      @keyup.enter="openCdlModal"
      @keyup.space="openCdlModal"
    >
      Looking for your Cisco Digital Learning courses?
    </button>
    <cu-circular-buttons
      class="blue ml-2 inline-block w-6 align-middle"
      value="?"
      primary
      @click.prevent="openCdlModal"
    />
    <cu-modal
      id="cdl-modal"
      class="cdl-modal"
      type="u-fill"
      showLogo="true"
      logoWidth="104px"
      width="744px"
      headerHeight="165px"
    >
      <div slot="body">
        <cu-h2 class="text-center"
          >Looking for your Cisco Digital Learning courses?</cu-h2
        >
        <p class="mt-5">
          If you are a Cisco Digital Learning (CDL) subscriber, please continue
          your learning in CDL for any content you've started there. CDL course
          progress, completion, and Continuing Education (CE) credits do not
          transfer to Cisco U.<br /><br />
          If you are not a CDL subscriber, please carry on with your Cisco U.
          experience. Cisco U. CE credits will be available.
        </p>
        <div class="mt-11 text-center">
          <cu-buttons
            btnType="primary"
            value="Go to Cisco Digital Learning"
            link="https://digital-learning.cisco.com"
            newTab="true"
          /><span class="sr-only">Go to Cisco Digital Learning</span>
        </div>
      </div>
    </cu-modal>
  </p>
</template>

<style scoped>
.cdl-modal {
  --modal-max-w: 744px;
}
</style>
