import buildGarden from "@/components/contentBits/buildGarden";
import dateFormats from "@/utils/dateFormats";
interface Data {
  type: string;
  vendor: string;
  premium: boolean;
  progress: string;
  date?: string;
  cardGarden: Array<any>;
}

const iconLimit: number = 4;

function returnType(cType: string): string {
  let rtn = "";
  const path = "learning-path";
  const videoSeries = "video-series";
  const ilt = "instructor-led";
  switch (cType) {
    case "path":
      rtn = path;
      break;
    case "webinar":
      rtn = "virtual-event";
      break;
    case "videoSeries":
    case "track":
    case videoSeries:
    case "video-track":
      rtn = videoSeries;
      break;
    case "video":
      rtn = "video";
      break;
    case "podcast":
      rtn = "podcast";
      break;
    case "course":
      rtn = "course";
      break;
    case "challenge":
      rtn = "challenge";
      break;
    case "tutorial":
      rtn = "tutorial";
      break;
    case ilt:
      rtn = ilt;
      break;
    case "practice-exam":
      rtn = "practice-exam";
      break;
    default:
      rtn = path;
  }
  return rtn;
}
function checkPremium(id: string, list: Array<string>): boolean {
  return !list.includes(id);
}
function getVendor(publisher_name: string): string {
  if (publisher_name) {
    return publisher_name.toLocaleLowerCase();
  }
  return "";
}
function getEventDate(event_date: string): string {
  if (event_date) {
    return (
      dateFormats.shortFmt(event_date) + " " + dateFormats.dayFmt(event_date)
    );
  }
  return "";
}
const newCard = {
  dataObject: (content: any, status: any) => {
    const garden = buildGarden(content, iconLimit);
    return {
      type: returnType(content?.type),
      vendor: getVendor(content?.publisher_name),
      premium: checkPremium(content?.guid, status?.userEntitlements ?? []),
      progress: content?.progress,
      date: getEventDate(content?.event_date),
      cardGarden: garden,
    } as Data;
  },
};
export default newCard;
