<script lang="ts">
import { defineComponent } from "vue";

import isUnauth from "@/utils/isUnauth";
import connectImg from "@/views/Certifications/assets/images/connect-image.png?url";
import prepareImgGroup from "@/views/Certifications/assets/images/prepare-cert-img-group.png?url";
import prepareImg from "@/views/Certifications/assets/images/prepare-img.png?url";
import scheduleImg from "@/views/Certifications/assets/images/schedule-cert-img.png?url";

export default defineComponent({
  props: {
    certification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      prepareImg,
      prepareImgGroup,
      connectImg,
      scheduleImg,
    };
  },
  methods: {
    isUnauth,
    scrollTo(id: string) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
});
</script>
<template>
  <div class="flex-col items-center border-t pb-20 lg:flex">
    <div class="mt-5">
      <div
        v-if="certification?.name"
        class="pb-20 text-center text-40 text-black-dark"
        data-cy="how-do-i-earn"
      >
        How do I earn my {{ certification.name }}?
      </div>
    </div>
    <div class="px-8 lg:mx-40 lg:flex lg:flex-row lg:px-0">
      <button
        class="prepare w-90 mb-8 flex-col gap-3 text-center hover:cursor-pointer focus:rounded-sm focus:ring-2 focus:ring-black-light lg:mb-0"
        tabindex="0"
        @click.prevent="scrollTo('prepare')"
        @keyup.enter.prevent="scrollTo('prepare')"
        @keyup.space.prevent="scrollTo('prepare')"
      >
        <div v-if="certification?.certification_level === 'Entry'">
          <img :src="prepareImg" alt="prepare" class="mx-auto h-36 w-60" />
        </div>
        <div
          v-else
          class="prepare-img h-40 bg-[url(@/views/Certifications/assets/images/prepare-cert-img-group.png)] bg-contain bg-center !bg-no-repeat lg:w-auto"
          data-cy="prepare-img"
        ></div>
        <div class="pt-2" data-cy="prepare-header">
          <span class="text-22 text-blue-deep">Prepare</span>
          <p class="earn-exm-text">
            Study for exams using the recommended learning and practice. Need to
            pass a lab exam? Try a practice lab.
          </p>
        </div>
      </button>
      <button
        class="connect w-90 -mt-16 mb-8 flex-col gap-3 text-center hover:cursor-pointer focus:rounded-sm focus:ring-2 focus:ring-black-light lg:mx-20 lg:mb-0"
        @click.prevent="scrollTo('connect')"
        @keyup.enter.prevent="scrollTo('connect')"
        @keyup.space.prevent="scrollTo('connect')"
      >
        <div
          class="connect-img h-56 w-auto bg-[url(@/views/Certifications/assets/images/connect-image.png)] bg-contain !bg-center !bg-no-repeat"
          data-cy="connect-img"
        ></div>
        <div class="pt-2" data-cy="connect-header">
          <span class="text-22 text-blue-deep">Connect</span>
          <p class="earn-exm-text">
            Connect with your peers in Cisco learning communities. Join a study
            group, ask questions, and learn from certification holders.
          </p>
        </div>
      </button>
      <button
        class="schedule w-90 mb-8 flex-col gap-3 text-center hover:cursor-pointer focus:rounded-sm focus:ring-2 focus:ring-black-light lg:mb-0"
        @click.prevent="scrollTo('schedule')"
        @keyup.enter.prevent="scrollTo('schedule')"
        @keyup.space.prevent="scrollTo('schedule')"
      >
        <div
          class="schedule-img h-40 w-auto bg-[url(@/views/Certifications/assets/images/schedule-cert-img.png)] bg-contain !bg-center !bg-no-repeat"
          data-cy="schedule-img"
        ></div>
        <div class="pt-2" data-cy="schedule-header">
          <span class="text-22 text-blue-deep">Schedule the exam</span>
          <p class="earn-exm-text">
            Take your certification exams. With online and in-person testing
            options, you can get certified on your own schedule.
          </p>
        </div>
      </button>
    </div>
  </div>
</template>

<style scoped>
.earn-exm-text {
  font-size: 14px;
  font-weight: 400;
  color: #606162;
  padding-top: 0.5rem;
}
</style>
