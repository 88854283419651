<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-up.js";
import "@cisco-u/icons/icons/cu-icon-chevron-down.js";

import { defineComponent } from "vue";

export default defineComponent({
  emits: ["expandCollapseInternal"],
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<template>
  <button
    tabindex="0"
    @click.prevent="$emit('expandCollapseInternal')"
    @keyup.space.prevent="$emit('expandCollapseInternal')"
    @keyup.enter.prevent="$emit('expandCollapseInternal')"
    class="relative flex w-full items-center justify-between pb-5 pt-6 text-left text-22 font-light leading-[120%] -tracking-[0.02em] text-black-dark"
  >
    <span class="sr-only" v-if="expanded">Close filters for </span>
    <span class="sr-only" v-else>Open filters for</span><slot />
    <cu-icon-chevron-up
      v-if="!expanded"
      class="inline-block cursor-pointer text-12 hover:text-blue-lightest"
    ></cu-icon-chevron-up>
    <cu-icon-chevron-down
      v-else
      class="inline-block cursor-pointer text-12 hover:text-blue-lightest"
    ></cu-icon-chevron-down>
  </button>
</template>
