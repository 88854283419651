<script lang="ts">
import { defineComponent } from "vue";

import LogoLink from "@/layout/LogoLink.vue";
export default defineComponent({
  emits: ["hide-global-success"],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  components: { LogoLink },

  methods: {
    closeModal() {
      this.$emit("hide-global-success");
    },
  },
});
</script>

<template>
  <transition name="modal">
    <div
      v-if="showModal"
      @click="closeModal()"
      @keyup.enter="closeModal()"
      @keyup.space="closeModal()"
      data-cy="success-modal-mask"
      class="modal-mask fixed left-0 right-0 top-0 z-[9998] table h-full w-full backdrop-blur-[0.4375rem]"
    >
      <div
        class="modal-wrapper table-cell h-auto py-4 align-middle"
        v-click-outside="closeModal"
      >
        <div
          class="mx-auto my-0 h-[12.375rem] max-w-[56rem] overflow-hidden rounded-t-lg border border-b-0 border-black-light bg-blue py-5 dark:bg-black"
        >
          <div class="modal-header mt-0">
            <div
              class="ml-4 flex items-center justify-between text-black dark:text-white"
            >
              <div class="relative m-auto w-full items-center text-center">
                <button
                  tabindex="0"
                  data-cy="close-success-alert-modal"
                  class="absolute right-4 cursor-pointer text-12 text-white-lightest"
                  @click="closeModal()"
                  @keyup.enter="closeModal()"
                  @keyup.space="closeModal()"
                >
                  X
                </button>
                <div class="m-auto my-4 w-[6.875rem]">
                  <logo-link class="text-29 text-white" :dark="true" />
                </div>
                <h5 class="text-36 font-light text-white-lightest">Success</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal-container relative mx-auto my-0 min-h-[19.875rem] max-w-[56rem] rounded-b-lg border border-t-0 border-black-light bg-white-lightest py-5 dark:bg-black"
        >
          <div class="modal-body my-5 px-[1.875rem] pb-10 text-black-dark">
            The survey was successfully reset
          </div>
          <div class="absolute bottom-0 w-full text-center">
            <div class="my-6">
              <button
                tabindex="0"
                data-cy="close-success-alert-ok"
                @click="closeModal()"
                @keyup.enter="closeModal()"
                @keyup.space="closeModal()"
                class="btn my-4 inline-flex items-center bg-blue text-white shadow-md transition duration-150 ease-in-out hover:border-blue-700 hover:bg-blue-lightest hover:text-black hover:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
