<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";
import "@cisco-u/links-external/cu-links-external.js";
import "@cisco-u/buttons/cu-buttons.js";

import { defineComponent } from "vue";

import learningPathResult from "@/components/contentBits/learningPathResult.vue";
import practiceExamResult from "@/components/contentBits/practiceExamResult.vue";
import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  emits: ["showConcentration"],
  components: {
    learningPathResult,
    practiceExamResult,
  },
  props: {
    certification: {
      type: Object,
      required: true,
    },
    exCardCore: {
      type: Object,
      required: true,
    },
    exCardEntry: {
      type: Object,
      required: true,
    },
    exCardConcentration: {
      type: Object,
      required: true,
    },
    status: {
      type: Object,
      default: null,
    },
    communities: {
      type: Object,
      required: true,
    },
    recommendedPaths: {
      type: Array,
      required: true,
    },
    currentExam: {
      type: Object,
      default: null,
    },
    pathConc: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showPathsOfExam(): any {
      let result: any = [];
      if (!this.exCardCore?.prescribed_prep_material?.length) return result;
      this.exCardCore.prescribed_prep_material?.forEach((material: any) => {
        let path = this.recommendedPaths?.find(
          (path: any) => path.guid == material.guid
        );
        if (path) {
          result = result.concat(path);
        }
      });
      return result;
    },
  },
  methods: {
    isUnauth,
    showConcentration(exam: any) {
      this.$emit("showConcentration", exam);
    },
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>

<template>
  <div class="border-b-gray px-4 pb-20 lg:px-44" data-cy="professional-prepare">
    <div class="mt-5 text-41 text-black-light">
      <span id="prepare" class="scroll-mt-28">Prepare</span>
    </div>
    <p :class="['text-18', { 'pt-3.5': isUnauth() }]">
      To earn your certification, you must pass the core exam and lab exam.
    </p>

    <div
      class="my-8 h-[3.125rem] text-22 font-bold text-black"
      data-cy="step1-title"
    >
      Step 1: Learning and practice for the core exam
    </div>
    <p class="mb-5 text-18">
      Use the following resources on your certification journey to success.
    </p>
    <div class="flex-row flex-wrap lg:flex" data-cy="professional-step1">
      <div class="flex-row lg:flex" v-for="path in showPathsOfExam" :key="path">
        <learning-path-result :result="path" :status="status" class="pb-4" />
        <div class="mb-4 ml-5">
          <practice-exam-result
            v-if="exCardCore?.practiceExam"
            tabindex="0"
            :result="exCardCore.practiceExam"
            :status="status"
          />
        </div>
        <div class="schedule mt-10 lg:ml-5">
          <p class="text-30">Schedule Core Exam</p>
          <p
            :class="[
              'mb-7 text-18',
              { 'mt-5 text-black-lightest': isUnauth() },
            ]"
          >
            Feeling ready? <br />
            If so, you've got this!
          </p>
          <cu-links-external
            v-if="exCardCore?.exam_number"
            type="line-diagonal"
            @click="sendUnauthTelemetry('Go to CertMetrics')"
            :text="
              'Schedule the ' +
              exCardCore.exam_number +
              ' ' +
              exCardCore.acronym +
              ' exam today'
            "
            url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
            linkTitle="Go to CertMetrics"
            noUnderline="true"
            color="blue-light"
            class="mt-6"
          />
        </div>
      </div>
    </div>
    <div
      id="step2"
      class="my-8 h-[3.125rem] scroll-mt-28 text-22 font-bold text-black"
      data-cy="step2-title"
    >
      Step 2: Learning and practice for the concentration exam
    </div>
    <p class="mb-2">
      Choose the concentration exam for the technology you want to specialize
      in. <br />
      Watch for the learning and practice to help get you there.
    </p>

    <div data-cy="professional-step2">
      <p class="mb-[0.688rem] font-cisco">CHOOSE A CONCENTRATION</p>
      <div class="flex w-1/2 flex-wrap pb-5">
        <div v-for="exams in certification?.exam" :key="exams.id">
          <div v-if="exams.type == 'concentration'">
            <cu-buttons
              btntype="secondary"
              :color="currentExam.id == exams.id ? 'black' : ''"
              :textColor="currentExam.id == exams.id ? 'white' : ''"
              :value="exams.exam_number + ' ' + exams.acronym"
              size="sm"
              class="mr-2"
              @click="showConcentration(exams)"
            />
          </div>
        </div>
      </div>

      <div class="mt-8 border-t-2" v-if="currentExam">
        <div class="my-10 text-30">
          {{ currentExam.exam_number + " " + currentExam.acronym }}
        </div>
        <div v-if="currentExam.prescribed_prep_material != ''">
          <div class="pb-4 text-18">
            {{ currentExam.summary }}
          </div>
          <div class="flex flex-row">
            <learning-path-result
              :result="pathConc"
              :status="status"
            ></learning-path-result>
            <div class="mb-4 ml-5">
              <practice-exam-result
                v-if="currentExam.practiceExam"
                tabindex="0"
                :result="currentExam.practiceExam"
                :status="status"
              />
            </div>
            <div class="schedule mt-10 lg:ml-5">
              <p class="text-30">Schedule Concentration Exam</p>
              <p
                :class="[
                  'mb-7 text-18',
                  { 'mt-5 text-black-lightest': isUnauth() },
                ]"
              >
                Feeling ready? <br />
                If so, you've got this!
              </p>
              <cu-links-external
                type="line-diagonal"
                @click="sendUnauthTelemetry('Go to CertMetrics')"
                :text="
                  'Schedule the ' +
                  exCardConcentration.exam_number +
                  ' ' +
                  exCardConcentration.acronym +
                  ' exam today'
                "
                url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
                linkTitle="Go to CertMetrics"
                noUnderline="true"
                color="blue-light"
                class="mt-6"
              ></cu-links-external>
            </div>
          </div>
        </div>
        <div v-else class="text-18">
          If you can't find any Cisco U. training resources,
          <cu-links-external
            type="line-diagonal"
            @click="sendUnauthTelemetry('Go to Communities')"
            text="join a community"
            :url="communities.URL"
            linkTitle="Go to Communities"
            noUnderline="true"
            color="blue-light"
            class="mt-6"
          ></cu-links-external>
          on the Cisco Learning Network to connect with certification experts
          and get learning guidance from your peers.
          <div class="schedule mt-10 lg:ml-5">
            <p class="text-30">Schedule Concentration Exam</p>
            <p
              :class="[
                'mb-7 text-18',
                { 'mt-5 text-black-lightest': isUnauth() },
              ]"
            >
              Feeling ready? <br />
              If so, you've got this!
            </p>
            <cu-links-external
              type="line-diagonal"
              @click="sendUnauthTelemetry('Go to CertMetrics')"
              :text="
                'Schedule the ' +
                exCardConcentration.exam_number +
                ' ' +
                exCardConcentration.acronym +
                ' exam today'
              "
              url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
              linkTitle="Go to CertMetrics"
              noUnderline="true"
              color="blue-light"
              class="mt-6"
            ></cu-links-external>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
