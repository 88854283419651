<script lang="ts">
import { defineComponent } from "vue";

import logoLink from "@/layout/LogoLink.vue";

export default defineComponent({
  components: { logoLink },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    navForYou: {
      type: String,
      default: "",
    },
    navExplore: {
      type: String,
      default: "",
    },
    navCommunity: {
      type: String,
      default: "",
    },
  },
});
</script>

<template>
  <div class="flex items-center justify-start">
    <logo-link
      :dark="dark"
      logo-link-tag-header="logo-link-header"
      class="text-29"
    />

    <ul
      class="border-black-dark-light ml-4 flex items-center justify-start gap-4 space-x-2 py-5 text-16 font-light lg:py-0 xl:ml-10 xl:gap-8 xxl:ml-20 xxl:text-22"
    >
      <li>
        <router-link
          tabindex="0"
          :data-cy="!dark ? navForYou : `${navForYou}-dark`"
          active-class="underline"
          class="inline-block whitespace-nowrap hover:underline focus:underline focus-visible:ring-2"
          :to="{ name: 'ForYou' }"
          >For You</router-link
        >
      </li>
      <li>
        <router-link
          tabindex="0"
          :data-cy="!dark ? navExplore : `${navExplore}-dark`"
          active-class="underline"
          class="inline-block whitespace-nowrap hover:underline focus:underline focus-visible:ring-2"
          :to="{ name: 'Explore' }"
          >Explore</router-link
        >
      </li>
      <li>
        <router-link
          tabindex="0"
          :data-cy="!dark ? navCommunity : `${navCommunity}-dark`"
          active-class="underline"
          class="inline-block whitespace-nowrap hover:underline focus:underline focus-visible:ring-2"
          :to="{ name: 'certifications' }"
          >Certifications</router-link
        >
      </li>
      <li>
        <router-link
          tabindex="0"
          :data-cy="!dark ? navCommunity : `${navCommunity}-dark`"
          active-class="underline"
          class="inline-block whitespace-nowrap hover:underline focus:underline focus-visible:ring-2"
          :to="{ name: 'community-landing-page' }"
          >Communities</router-link
        >
      </li>
      <li>
        <router-link
          tabindex="0"
          data-cy="view-plans-link-header"
          active-class="underline"
          class="inline-block whitespace-nowrap hover:underline focus:underline focus-visible:ring-2"
          :to="{ name: 'subscription' }"
          >Plans</router-link
        >
      </li>
    </ul>
  </div>
</template>
