<script lang="ts">
import "@cisco-u/icon-launch/cu-icon-launch.js";
import "@cisco-u/stickers/cu-stickers.js";
import "@cisco-u/top-header/cu-top-header.js";
import "@cisco-u/footer/cu-footer.js";
import "@cisco-u/purchase-card/cu-purchase-card.js";
import "@cisco-u/grid-box/cu-grid-box.js";
import "@cisco-u/links-external/cu-links-external.js";
import "@cisco-u/buttons/cu-buttons.js";
import "@cisco-u/testimonial-swiper/cu-testimonial-swiper.js";
import "@cisco-u/testimonial-slide/cu-testimonial-slide.js";
import "@cisco-u/badge/cu-badge.js";
import "@cisco-u/new-card/cu-new-card.js";
import "@cisco-u/tags/cu-tags.js";
import "@cisco-u/progress-bar/cu-progress-bar.js";
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";

import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import CertCard from "@/components/contentBits/certCard.vue";
import EventResult from "@/components/contentBits/eventsResult.vue";
import instructorLedResult from "@/components/contentBits/instructorLedResult.vue";
import LearningPathResult from "@/components/contentBits/learningPathResult.vue";
import PodCastCard from "@/components/contentBits/podCastCard.vue";
import practiceExamResult from "@/components/contentBits/practiceExamResult.vue";
import TutorialsResult from "@/components/contentBits/tutorialsResult.vue";
import VideosCard from "@/components/contentBits/videosCard.vue";
import LazyImage from "@/components/LazyImage.vue";
import unauthService from "@/services/unauthService";
import isUnauth from "@/utils/isUnauth";
import openWindow from "@/utils/openWindow";
import telemetry from "@/utils/telemetry";

import assets from "./assets";

const sanitizeUrl = await import("@braintree/sanitize-url");

export default defineComponent({
  data() {
    return {
      joinCiscoUrl: "/login",
      marketoUrl:
        "https://mkto.cisco.com/ciscou-contact-sales.html?utm_campaign=ciscou&utm_source=web-cu&utm_medium=cu-unauth",
      assets,
      freeContent: [] as any,
      newContent: [] as any,
      btn: "button",
      link: "link",
      joinCiscoText: "Join Cisco U. Free",
      comparePlansText: "Compare plans",
      viewTitles: "View titles",
      exploreLink: "/explore",
    };
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      vm.initialize();
    });
  },
  beforeRouteUpdate(to: any, from: any, next: any) {
    this.initialize();
    next();
  },
  components: {
    CertCard,
    PodCastCard,
    LearningPathResult,
    EventResult,
    VideosCard,
    TutorialsResult,
    instructorLedResult,
    practiceExamResult,
    LazyImage,
  },
  methods: {
    initialize() {
      if (this.isUnauth()) {
        this.fetchNewContent();
        this.fetchFreeData();
      } else {
        this.$router.push("/for-you");
      }
    },
    isUnauth,
    openWindow,
    fetchNewContent() {
      unauthService.landingContent
        .fetchNewContent()
        .then((response: any) => {
          if (response && response.Data) {
            this.newContent = response.Data;
            this.newContent.forEach((element: any) => {
              element.tags = ["New"];
            });
          }
        })
        .catch(() => {});
    },
    fetchFreeData() {
      unauthService.landingContent
        .fetchFreeContent()
        .then((response: any) => {
          if (response && response.Data) {
            this.freeContent = response.Data;
          }
        })
        .catch(() => {});
    },
    componentType(type: string): string {
      let rtn: string = "";
      switch (type) {
        case "podcast":
          rtn = "PodCastCard";
          break;
        case "video":
        case "track":
        case "video-track":
          rtn = "VideosCard";
          break;
        case "course":
          rtn = "CertCard";
          break;
        case "challenge":
          rtn = "challengeResult";
          break;
        case "path":
        case "paths":
          rtn = "LearningPathResult";
          break;
        case "webinar":
          rtn = "EventResult";
          break;
        case "instructor-led":
          rtn = "instructorLedResult";
          break;
        case "tutorial":
          rtn = "TutorialsResult";
          break;
        case "practice-exam":
          rtn = "practiceExamResult";
          break;
      }
      return rtn;
    },

    async handleOnClick(
      title: string,
      requestUrl: string,
      type: string,
      query?: any
    ) {
      await this.sendTelemetry(title, type)
        .then(() => {
          this.navigateToTarget(requestUrl, query);
        })
        .catch((error) => {
          this.navigateToTarget(requestUrl, query);
        });
    },

    navigateToTarget(requestUrl: string, query?: any) {
      if (requestUrl === "/login") {
        window.location.href = window.location.origin + "/login";
      } else if (requestUrl === this.marketoUrl) {
        openWindow(this.marketoUrl, "_blank", "");
      } else if (query) {
        this.$router.push({ path: requestUrl, query: query });
      } else {
        this.$router.push({ path: requestUrl });
      }
    },

    async sendTelemetry(title: string, type: string) {
      if (this.isUnauth()) {
        await telemetry.click_on_cta(
          this.$route.fullPath,
          title,
          this.isUnauth(),
          type
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      essentialsBundle: "content/essentialsBundle",
      allAccessBundle: "content/allAccessBundle",
      essentialsId: "content/essentialsId",
      allAccessId: "content/allAccessId",
      freeId: "content/freeId",
    }),
    marketoLink() {
      return sanitizeUrl.sanitizeUrl(this.marketoUrl);
    },
    hasDiscount() {
      return [this.essentialsBundle, this.allAccessBundle].some(
        (bundle) => bundle?.discount_price > 0
      );
    },
  },
});
</script>

<template>
  <div class="bg-white-lightest font-cisco">
    <div class="mx-auto max-w-[90rem] px-5 pb-[6.25rem] font-light">
      <div
        class="relative flex flex-wrap rounded-[0.9375rem] bg-black px-6 pb-20 pt-10 text-white-lightest md:flex-nowrap md:justify-center md:gap-[3.75rem]"
      >
        <div class="w-full pt-10 md:max-w-[33.75rem]">
          <h1
            class="w-full text-60 leading-[1.1] -tracking-[1.2px] lg:max-w-[27.5rem]"
          >
            Tech learning, shaped to you.
          </h1>
          <p class="mt-6 text-18 leading-[1.3] text-white-dark">
            Let Cisco U. empower you or your teams to learn the skills of the
            future today. Elevate your technical expertise with leading-edge
            content and a personalized learning experience.
          </p>
          <div class="mt-[3.75rem] flex gap-10">
            <cu-buttons
              data-cy="join-cisco-btn"
              @click="() => handleOnClick(joinCiscoText, '/login', btn)"
              :value="joinCiscoText"
              btnType="primary"
              size="lg"
            ></cu-buttons>
            <cu-buttons
              data-cy="compare-plans-btn"
              @click="() => handleOnClick(comparePlansText, 'plans', btn)"
              :value="comparePlansText"
              btnType="tertiary"
              size="lg"
            ></cu-buttons>
          </div>
        </div>
        <div class="mx-auto mt-4 lg:mx-0 lg:mt-0">
          <LazyImage
            width="339"
            height="368"
            :src="assets.unauthLandingHeading1"
            :srcset="assets.unauthLandingHeadingSrcSet"
            sizes="339px"
            alt="Person in glasses smiling on a blue background"
            class="w-[21.125rem]"
            :lazy="false"
            :preload="true"
          />
        </div>
        <div class="absolute -bottom-[8.625rem] -left-[6.875rem]">
          <cu-badge color="tech" width="216.75rem"></cu-badge>
        </div>
      </div>

      <div class="mt-36 flex flex-col lg:mt-20 lg:flex-row">
        <div class="flex-1">
          <div class="mx-auto w-full lg:max-w-[23.75rem]">
            <h2
              class="w-full text-14 leading-[1.1] -tracking-[1.12] lg:max-w-[22.5rem]"
            >
              Your terms. Your future.
            </h2>
            <p class="mt-3 text-22 leading-[1.2]">
              Personalize learning for what <br />
              you or your teams want to achieve.
            </p>
            <div class="mb-6 mt-6 md:mb-0">
              <router-link
                tabindex="0"
                data-cy="view-learning"
                to="/explore"
                @click="
                  () => sendTelemetry('Explore our learning catalog', link)
                "
                class="flex flex-none cursor-pointer items-center text-16 font-normal text-blue-light"
              >
                Explore our learning catalog
                <cu-icon-chevron-right class="ml-1" />
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="flex w-full flex-col gap-6 md:w-[31.25rem] md:px-6">
            <div>
              <cu-icon-launch
                @cu-icon-launch="
                  () => handleOnClick('Get certified', 'certifications', link)
                "
                sticker="true"
              >
                <div slot="sticker">
                  <cu-stickers
                    shape="sleek-rectangle"
                    stickerDiameter="2rem"
                    bgColor="#00BCEB"
                  ></cu-stickers>
                </div>
                <div slot="heading">Get certified</div>
                <div slot="description">
                  Prepare for certifications with Cisco U. trusted content.
                </div>
              </cu-icon-launch>
            </div>
            <div>
              <cu-icon-launch
                @cu-icon-launch="
                  () => handleOnClick('Learn new skills', 'explore', link)
                "
                sticker="true"
              >
                <div slot="sticker">
                  <cu-stickers
                    shape="star-3"
                    stickerDiameter="2rem"
                    bgColor="#FBAB2C"
                  ></cu-stickers>
                </div>
                <div slot="heading">Learn new skills</div>
                <div slot="description">
                  Thrive in your field or take teams to the next level.
                </div>
              </cu-icon-launch>
            </div>
            <div>
              <cu-icon-launch
                @cu-icon-launch="
                  () =>
                    handleOnClick(
                      'Solve the problem at hand',
                      'tutorials',
                      link
                    )
                "
                sticker="true"
              >
                <div slot="sticker">
                  <cu-stickers
                    shape="star-1"
                    stickerDiameter="2rem"
                    bgColor="#F34240"
                  ></cu-stickers>
                </div>
                <div slot="heading">Solve the problem at hand</div>
                <div slot="description">
                  Get just in time product training. It's fase and it's free.
                </div>
              </cu-icon-launch>
            </div>
            <div>
              <cu-icon-launch
                @cu-icon-launch="
                  () =>
                    handleOnClick(
                      'Join a global community',
                      'communities',
                      link
                    )
                "
                sticker="true"
              >
                <div slot="sticker">
                  <cu-stickers
                    shape="star-2"
                    stickerDiameter="2rem"
                    bgColor="#0051AF"
                  ></cu-stickers>
                </div>
                <div slot="heading">Join a global community</div>
                <div slot="description">
                  Collaborate with thousands of experts and peers.
                </div>
              </cu-icon-launch>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2
          class="mb-[2.6875rem] mt-[3.875rem] text-center text-36 lg:pl-[6.15rem] lg:text-left"
        >
          Get started with free resources.
        </h2>
        <div
          class="flex flex-wrap justify-center gap-4 lg:justify-start lg:pl-[6.15rem]"
          v-if="freeContent"
        >
          <div v-for="result in freeContent" :key="result">
            <component
              :is="componentType(result.type)"
              :result="result"
              :status="null"
              tabindex="0"
              :freeContent="true"
            />
          </div>
        </div>
      </div>

      <div class="mt-20 flex flex-wrap justify-center gap-4">
        <div id="practice_handson">
          <div
            class="bg-white-light px-6 py-8 md:h-[32.1875rem] md:w-[43.125rem]"
          >
            <h3
              class="mx-auto text-center text-28 font-normal leading-10 text-black md:mb-4 md:text-46 md:font-lighter"
            >
              Practice with<br />
              hands-on labs.
            </h3>
            <router-link
              data-cy="explore-labs-link"
              tabindex="0"
              @click="() => sendTelemetry('Explore hands-on labs', link)"
              :to="{ path: 'paths', query: { type: 'labs' } }"
              class="block cursor-pointer items-center text-center text-16 font-normal text-blue-light"
            >
              Explore hands-on labs
              <cu-icon-chevron-right class="ml-1" />
            </router-link>
            <div class="flex items-center justify-center">
              <LazyImage
                :src="assets.ulLaptop1"
                :srcset="assets.ulLaptopSrcSet"
                alt="open laptop"
              />
            </div>
          </div>
        </div>

        <div id="immerse_instructorLed">
          <div
            class="bg-white-light px-6 py-8 md:h-[32.1875rem] md:w-[43.125rem]"
          >
            <h3
              class="mx-auto text-center text-28 font-normal leading-10 text-black md:mb-4 md:text-46 md:font-lighter"
            >
              Immerse yourself in live instructor-led training.
            </h3>
            <router-link
              data-cy="explore-instructorLed-link"
              slot="link"
              tabindex="0"
              @click="
                () => sendTelemetry('Discover instructor-led training', link)
              "
              to="/instructorled"
              class="block cursor-pointer items-center text-center text-16 font-normal text-blue-light"
            >
              Discover instructor-led training
              <cu-icon-chevron-right class="ml-1" />
            </router-link>
            <div class="flex items-center justify-center">
              <LazyImage
                :src="assets.ullLaptop1"
                width="434"
                height="247"
                :srcset="assets.ullLaptopSrcSet"
                sizes="434px"
                alt="open laptop with a person holding a tablet"
              />
            </div>
          </div>
        </div>

        <div id="follow_learningPath">
          <div
            class="bg-white-light px-6 py-8 md:h-[32.1875rem] md:w-[43.125rem]"
          >
            <h3
              class="mx-auto text-center text-28 font-normal leading-10 text-black md:mb-4 md:text-46 md:font-lighter"
            >
              Follow a Learning Path to guided success.
            </h3>
            <router-link
              data-cy="discover-learningPaths-link"
              tabindex="0"
              @click="() => sendTelemetry('Pick your Learning Path', link)"
              to="/paths"
              class="block cursor-pointer items-center text-center text-16 font-normal text-blue-light"
            >
              Pick your Learning Path
              <cu-icon-chevron-right class="ml-1" />
            </router-link>
            <div class="flex items-center justify-center">
              <LazyImage
                :src="assets.ulPeople1"
                width="414"
                height="293"
                :srcset="assets.ulPeopleSrcSet"
                sizes="414px"
                alt="two smiling people facing forward"
              />
            </div>
          </div>
        </div>

        <div id="cert_exams">
          <div
            class="bg-white-light px-6 py-8 md:h-[32.1875rem] md:w-[43.125rem]"
          >
            <h3
              class="mx-auto text-center text-28 font-normal leading-10 text-black md:mb-4 md:text-46 md:font-lighter"
            >
              Check cert exam readiness with practice exams.
            </h3>
            <router-link
              data-cy="explore-practiceExams-link"
              tabindex="0"
              @click="
                () => sendTelemetry('View a practice exam in action', link)
              "
              to="/exams"
              class="block cursor-pointer items-center text-center text-16 font-normal text-blue-light"
            >
              View a practice exam in action
              <cu-icon-chevron-right class="ml-1" />
            </router-link>
            <div class="flex items-center justify-center">
              <LazyImage
                :src="assets.uleLaptop1"
                width="594"
                height="246"
                :srcset="assets.uleLaptopSrcSet"
                sizes="594px"
                alt="laptop with floating success text hovering it"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2
          class="mb-[2.6875rem] mt-[3.875rem] text-center text-34 lg:pl-[6.15rem] lg:text-left lg:text-36"
        >
          Stay up to date with what’s new on Cisco U.
        </h2>
        <div
          class="flex flex-wrap justify-center gap-4 lg:justify-start lg:pl-[6.15rem]"
        >
          <div v-for="result in newContent" :key="result">
            <component
              :is="componentType(result.type)"
              :result="result"
              :status="null"
              tabindex="0"
            />
          </div>
        </div>
      </div>
      <div class="mt-[3.75rem] text-center">
        <h2 class="text-46 leading-[1.2]">A plan for every learning goal</h2>

        <p class="mx-auto mt-4 max-w-[60.5rem] leading-[1.4]">
          With three available plans, there's a Cisco U. subscription for every
          learner. Whether you and your team are interested in professional
          certifications or simply want to start with free content, we'll be
          there every step of the way.
        </p>
        <div class="mt-10 flex flex-wrap justify-center gap-5">
          <div>
            <cu-purchase-card :discounted="hasDiscount ? '0' : null" price="0">
              <div slot="heading">Cisco U. Free</div>
              <div slot="description">
                Start your learning journey with free content. Upskill with
                select Learning Paths. Solve the problem at hand with tutorials,
                videos, webinars, and more.
              </div>
              <div slot="currency">
                <cu-tags text="USD" grey="true"></cu-tags>
              </div>
              <p slot="details">Includes:</p>
              <ul slot="details">
                <li>Podcasts, webinars, videos, and tutorials</li>
                <li>Technical skills assessments</li>
                <li>Technical and certification digital communities</li>
                <li>Network automation Learning Path</li>
              </ul>
              <cu-buttons
                data-cy="start-free-btn"
                @click="() => handleOnClick('Start for free', '/login', btn)"
                slot="primary-btn"
                btntype="primary"
                value="Start for free"
                width="100%"
              ></cu-buttons>
              <cu-buttons
                data-cy="view-titles-btn"
                @click="
                  () =>
                    handleOnClick(viewTitles, exploreLink, btn, {
                      subscription: freeId,
                    })
                "
                slot="secondary-btn"
                btntype="secondary"
                :value="viewTitles"
                width="100%"
              ></cu-buttons>
              <div slot="footer">Upgrade Anytime</div>
            </cu-purchase-card>
          </div>

          <div v-if="essentialsBundle">
            <cu-purchase-card
              :discounted="hasDiscount ? essentialsBundle.discount_price : null"
              :price="essentialsBundle.price"
              :credits="essentialsBundle.clc_count"
            >
              <div slot="heading">{{ essentialsBundle.name }}</div>
              <div slot="description">
                Prep for industry-leading Associate-level certs with targeted
                Learning Paths, and improve practical skills with hands-on labs
                and multi-vendor training.
              </div>
              <div slot="currency">
                <cu-tags text="USD" grey="true"></cu-tags>
              </div>
              <p slot="details">Includes everything in Free, plus:</p>
              <ul slot="details">
                <li>IT domain fundamentals</li>
                <li>Professional and soft skills training</li>
                <li>Multi-vendor technology training</li>
                <li>Associate-level certification Learning Paths</li>
                <li>Essential self-paced hands-on labs</li>
              </ul>
              <cu-buttons
                data-cy="sign-in-buy-btn"
                @click="() => handleOnClick('Sign in to buy', '/login', btn)"
                slot="primary-btn"
                btntype="primary"
                value="Sign in to buy"
                width="100%"
              ></cu-buttons>
              <cu-buttons
                data-cy="view-titles-btn"
                @click="
                  handleOnClick(viewTitles, exploreLink, btn, {
                    subscription: essentialsId,
                  })
                "
                slot="secondary-btn"
                btntype="secondary"
                :value="viewTitles"
                width="100%"
              ></cu-buttons>
              <div slot="footer">
                {{ essentialsBundle.subscription_length_days }} Day Subscription
              </div>
            </cu-purchase-card>
          </div>

          <div v-if="allAccessBundle">
            <cu-purchase-card
              :discounted="hasDiscount ? allAccessBundle.discount_price : null"
              :price="allAccessBundle.price"
              :credits="allAccessBundle.clc_count"
            >
              <div slot="heading">{{ allAccessBundle.name }}</div>
              <div slot="description">
                Unlock all Cisco U. content and meet any certification goal with
                access to all cert prep Learning Paths, virtual labs, and
                in-depth product and solution training.
              </div>
              <div slot="currency">
                <cu-tags text="USD" grey="true"></cu-tags>
              </div>
              <p slot="details">Includes everything in Essentials, plus:</p>
              <ul slot="details">
                <li>All multi-vendor technology training</li>
                <li>Professional-level certification Learning Paths</li>
                <li>In-depth Cisco product and solution Learning Paths</li>
                <li>All self-paced hands-on labs</li>
              </ul>
              <cu-buttons
                data-cy="sign-in-buy-btn"
                @click="() => handleOnClick('Sign in to buy', '/login', btn)"
                slot="primary-btn"
                btntype="primary"
                value="Sign in to buy"
                width="100%"
              ></cu-buttons>
              <cu-buttons
                data-cy="view-titles-btn"
                @click="
                  handleOnClick(viewTitles, exploreLink, btn, {
                    subscription: allAccessId,
                  })
                "
                slot="secondary-btn"
                btntype="secondary"
                :value="viewTitles"
                width="100%"
              ></cu-buttons>
              <div slot="footer">
                {{ allAccessBundle.subscription_length_days }} Day Subscription
              </div>
            </cu-purchase-card>
          </div>
        </div>
      </div>
      <div class="mt-20 flex flex-wrap items-center justify-center gap-4">
        <div>
          <LazyImage
            :src="assets.unauthLandingManage1"
            :srcset="assets.unauthLandingManageSrcSet"
            alt="Two people conversating over coffee"
            class="w-[31.625rem]"
          />
        </div>
        <div class="max-w-[28rem]">
          <h2 class="text-14 leading-[1.1]">
            Easily manage your teams with the admin portal.
          </h2>
          <div class="mt-6">
            <cu-links-external
              @click="() => sendTelemetry('Request a demo', link)"
              data-cy="request-demo-link"
              type="chevron-right"
              text="Request a demo"
              :url="marketoLink"
              noUnderline="true"
              color="blue-light"
            ></cu-links-external>
          </div>
        </div>
      </div>

      <div class="mt-[3.75rem] flex flex-wrap justify-center gap-4">
        <div id="assign_learning">
          <cu-grid-box style="--grid-box-img-width: 27rem">
            <span slot="heading">Assign learning <br />for your team.</span>
            <cu-links-external
              @click="() => sendTelemetry('Request a demo', link)"
              slot="link"
              data-cy="request-demo-link"
              type="chevron-right"
              text="Request a demo"
              :url="marketoLink"
              noUnderline="true"
              color="blue-light"
            ></cu-links-external>
            <LazyImage
              slot="image"
              :src="assets.ulaLaptop1"
              width="433"
              height="246"
              :srcset="assets.ulaLaptopSrcSet"
              sizes="433px"
              alt="laptop showing learning paths assigned"
            />
          </cu-grid-box>
        </div>

        <div id="stay_onTop">
          <cu-grid-box style="--grid-box-img-width: 27rem">
            <span slot="heading">Stay on top <br />of team activity.</span>
            <cu-links-external
              @click="() => sendTelemetry('Request a demo', link)"
              slot="link"
              data-cy="request-demo-link"
              type="chevron-right"
              text="Request a demo"
              :url="marketoLink"
              noUnderline="true"
              color="blue-light"
            ></cu-links-external>
            <LazyImage
              slot="image"
              :src="assets.ulpLaptop1"
              width="434"
              height="246"
              :srcset="assets.ulpLaptopSrcSet"
              sizes="434px"
              alt="laptop showing learning paths accessed"
            />
          </cu-grid-box>
        </div>
      </div>

      <div class="mt-10">
        <cu-testimonial-swiper>
          <cu-testimonial-slide class="swiper-slide">
            <LazyImage
              width="1400"
              height="487"
              :src="assets.testimonialBannerA1"
              :srcset="assets.testimonialBannerASrcSet"
              sizes="100vw, (min-width: 1400px) 1400px"
              alt="Certifications changed my life and formed my career trajectory. They gave me the knowledge that I will probably always be able to get a job if I really need to. - Olivia Wolff"
            />
          </cu-testimonial-slide>
          <cu-testimonial-slide class="swiper-slide">
            <LazyImage
              width="1400"
              height="487"
              :src="assets.TestimonialBannerB1"
              :srcset="assets.testimonialBannerBSrcSet"
              sizes="100vw, (min-width: 1400px) 1400px"
              alt="What I really like about being in IT is that it's an environment of constant learning. The folks in IT that excel are the folks who have embraced that love of learning. They're always out there learning something new because there's always something new coming along. - Micheline Murphy"
            />
          </cu-testimonial-slide>
          <cu-testimonial-slide class="swiper-slide">
            <LazyImage
              width="1400"
              height="487"
              :src="assets.testimonialBannerC1"
              :srcset="assets.testimonialBannerCSrcSet"
              sizes="100vw, (min-width: 1400px) 1400px"
              alt="Learning is everything. Sometimes we come out of school and think, 'I've done all the learning I need to do. And I can just be static the rest of my life.' That's not true. It's only the beginning. - Jenny Guay"
            />
          </cu-testimonial-slide>
        </cu-testimonial-swiper>
      </div>

      <div class="mt-[8.125rem] text-center">
        <h2 class="text-46 leading-[1.1]">
          See personalized learning in action. <br />
          Learn how Cisco U. takes tech training to the next level.
        </h2>
        <div class="mt-10">
          <cu-buttons
            data-cy="request-demo-btn"
            @click="() => handleOnClick('Request a demo', marketoUrl, btn)"
            value="Request a demo"
            btnType="primary"
            size="lg"
          ></cu-buttons>
        </div>
      </div>
    </div>
  </div>
</template>
