<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: [
    "next-slide",
    "back-slide",
    "first-page",
    "second-page",
    "third-page",
  ],
  props: {
    currentSlideNumber: {
      type: Number,
      default: 0,
    },
    showNext: {
      type: Boolean,
      default: false,
    },
    allowNextNav: {
      type: Boolean,
      default: false,
    },
    showPrevious: {
      type: Boolean,
      default: false,
    },
    submitBtn: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <div v-if="currentSlideNumber != undefined">
    <div
      class="case-slider__navigation mb-8 flex w-full flex-row-reverse justify-between pt-[3.25rem] xsmobile:flex-col-reverse xsmobile:items-center"
    >
      <div class="next" v-if="showNext">
        <button
          data-cy="notSureButton"
          class="my-4 mr-[1.875rem] inline-flex cursor-pointer items-center text-sm text-blue-light"
          @click="$emit('next-slide', true)"
          @keyup.enter="$emit('next-slide', true)"
          @keyup.space="$emit('next-slide', true)"
        >
          I'm not sure yet
        </button>
        <button
          :data-cy="submitBtn ? 'submitButton' : 'nextButton'"
          :class="[
            'btn my-4 inline-flex h-[2.875rem] w-24 items-center text-white-lightest',
            allowNextNav
              ? 'bg-blue-light'
              : 'bg-gray-300 dark:bg-black-lightest',
            submitBtn ? ' pl-5' : '',
          ]"
          @click="$emit('next-slide')"
          @keyup.enter="$emit('next-slide')"
          @keyup.space="$emit('next-slide')"
          :disabled="!allowNextNav"
        >
          <span v-if="!submitBtn" class="flex items-center">
            <span class="-ml-[1.125rem] block pr-2.5 text-white-lightest"
              >Next</span
            >
            &nbsp;<img
              src="@/layout/InterestSurvey/assets/nextarrow.png"
              alt="next arrow"
            />
          </span>
          <span class="inline-flex items-center" v-else>Submit</span>
        </button>
      </div>

      <div class="prev" v-if="showPrevious">
        <button
          data-cy="backButton"
          class="btn my-4 inline-flex h-[2.875rem] items-center bg-transparent"
          @click="$emit('back-slide')"
          @keyup.enter="$emit('back-slide')"
          @keyup.space="$emit('back-slide')"
        >
          Back
        </button>
      </div>
    </div>

    <div
      class="swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal relative -top-[4.6875rem] mt-[6.25rem] flex flex-row justify-center pt-px"
    >
      <span
        class="swiper-pagination-bullet swiper-pagination-bullet-active"
        v-if="currentSlideNumber == 0"
      />
      <span
        v-else
        @click="$emit('first-page')"
        @keyup.enter="$emit('first-page')"
        @keyup.space="$emit('first-page')"
        class="swiper-pagination-bullet"
      />

      <span
        class="swiper-pagination-bullet swiper-pagination-bullet-active"
        v-if="currentSlideNumber == 1"
      />
      <span
        v-else
        @click="$emit('second-page')"
        @keyup.enter="$emit('second-page')"
        @keyup.space="$emit('second-page')"
        class="swiper-pagination-bullet"
      />

      <span
        class="swiper-pagination-bullet swiper-pagination-bullet-active"
        v-if="currentSlideNumber == 2"
      />
      <span
        v-else
        @click="$emit('third-page')"
        @keyup.enter="$emit('third-page')"
        @keyup.space="$emit('third-page')"
        class="swiper-pagination-bullet"
      />
    </div>
  </div>
</template>
