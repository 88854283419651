import { z } from "zod";

const metadata = z
  .object({
    catalogID: z.string().nullish(),
    courseID: z.string().nullish(),
    course_title: z.string().nullish(),
    index: z.string().nullish(),
    keywords: z.string().nullish(),
    language: z.string().nullish(),
    section_guid: z.string().nullish(),
    section_title: z.string().nullish(),
    skillLevels: z.string().nullish(),
    technologies: z.string().nullish(),
    topic_guid: z.string().nullish(),
    topic_objective_guid: z.string().nullish(),
    topic_objective_title: z.string().nullish(),
    topic_title: z.string().nullish(),
    url: z.string().nullish(),
  })
  .passthrough();

const courses = z
  .object({
    data: z.any().nullish(),
    distances: z.array(z.array(z.number()).nullish()).nullish(),
    documents: z.array(z.array(z.string()).nullish()).nullish(),
    embeddings: z.any().nullish(),
    ids: z.array(z.array(z.string()).nullish()).nullish(),
    metadatas: z.array(z.array(metadata).nullish()).nullish(),
  })
  .passthrough()
  .nullish();

const processQuery = z
  .object({
    answer: z.string(),
    order: z.number().nullish(),
    sources: z
      .object({
        courses: courses,
      })
      .nullish(),
    user_query: z.string().nullish(),
    status: z.number().nullable(),
  })
  .passthrough();

export const processQueryResponse = z.string().or(processQuery);
 
const aiAssistantResponseResult = z.object({
  answer: z.string(),
  order: z.number().nullish(),
  sources: z
    .object({
      courses: courses,
    })
    .nullish(),
  user_query: z.string().nullish(),
  type: z.enum(["success", "error"]),
  answersChunk: z.array(z.any()).optional()
});

const aiAssistantChunkResults = z.object({
  answersChunk: z.array(z.any()).optional()
})

export const processUserRequest = z.object({
  user_query: z.string(),
  prompt_type: z.string(),
  rag_enabled: z.boolean(),
});

export type AiAssistantResponseResult = z.infer<
  typeof aiAssistantResponseResult
>;

export type AiAssistantChunkResults = z.infer<typeof aiAssistantChunkResults>;