<script lang="ts">
import { defineComponent } from "vue";

import AnchorTargetBlankCallback from "@/components/AnchorTargetBlankCallback.vue";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  components: {
    AnchorTargetBlankCallback,
  },
  data() {
    return {
      blueIcon: false,
      showTooltip: false,
      url: "https://www.cisco.com/c/en/us/training-events/training-certifications/training-catalog.html?delivery_options=instructor_led",
    };
  },
  methods: {
    isUnauth,
    async sendTelem() {
      await telemetry.click_on_cta(
        this.$route.path,
        "Training Catalog",
        this.isUnauth(),
        "link"
      );
      window.open(this.url, "_blank", "noopener");
    },
  },
});
</script>

<template>
  <div class="ilt-md relative hidden justify-center md:block">
    <div
      class="inline-block"
      @mouseenter.prevent="blueIcon = true"
      @mouseleave.prevent="blueIcon = false"
      @focusin="blueIcon = true"
      @focusout="blueIcon = false"
    >
      <slot></slot>
    </div>

    <div
      class="tooltip-container focus group/tp relative inline-flex items-center"
      v-click-outside="() => (showTooltip = false)"
    >
      <button
        @click.prevent="showTooltip = !showTooltip"
        @keyup.space.prevent="showTooltip = !showTooltip"
        @keyup.enter.prevent="showTooltip = !showTooltip"
        class="icon-wrapper relative -top-0.5 inline-flex cursor-pointer pl-4"
      >
        <div
          :class="[
            'leading-0 block h-5 w-5 rounded-full border border-[#0176D4] text-sm font-light',
            {
              'bg-white-lightest text-[#0176d4] hover:bg-[#0176df] hover:text-white':
                !blueIcon,
              'bg-[#0176D4] text-white-lightest': blueIcon,
            },
          ]"
        >
          <span class="sr-hidden">i</span
          ><span class="sr-only">information</span>
        </div>
      </button>

      <div
        @mouseenter.prevent="blueIcon = false"
        @mouseleave.prevent="
          blueIcon = false;
          showTooltip = false;
        "
        @focusin="blueIcon = false"
        @focusout="
          blueIcon = false;
          showTooltip = false;
        "
        :class="[
          'tooltip absolute bottom-[142%] z-10 h-[2.1875rem] whitespace-nowrap rounded-[0.25rem] bg-[#0d0d0e] px-[0.5rem] text-14 font-light leading-[2.1875rem] text-white opacity-0 transition-opacity delay-500 focus-within:opacity-100 group-hover/tp:opacity-100',
          { 'opacity-100': showTooltip },
        ]"
        data-cy="tooltip"
      >
        View all instructor-led training at the
        <AnchorTargetBlankCallback
          :url="url"
          class="text-blue-light hover:underline focus:underline"
          @clicked="sendTelem"
        >
          Training Catalog</AnchorTargetBlankCallback
        >.
      </div>
    </div>
  </div>
</template>

<style scoped>
.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 9%;
  transform: translateX(-100%);
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.375rem solid #0d0d0e;
}
</style>
