import * as certifications from "./certifications";
import * as challenges from "./challengeContent";
import * as communitiesContent from "./communitiesContent";
import * as courses from "./courses";
import * as landingContent from "./landingContent";
import * as learningPathsContent from "./learningPathsContent";
import * as subscriptions from "./subscriptions";
import * as telemetry from "./telemetry";
import * as tutorialContent from "./tutorialContent";

export default {
  certifications,
  courses,
  communitiesContent,
  landingContent,
  learningPathsContent,
  subscriptions,
  telemetry,
  tutorialContent,
  challenges,
};
