<script lang="ts">
import "@cisco-u/checkbox/cu-checkbox.js";
import "@cisco-u/radio-buttons/cu-radio-buttons.js";
import "@cisco-u/icons/icons/cu-icon-chevron-up.js";
import "@cisco-u/icons/icons/cu-icon-chevron-down.js";

import { startCase } from "lodash-es";
import { defineComponent } from "vue";

import formatContentTypes from "@/utils/formatContentTypes";
import expandCollapseHeading from "@/views/Search/SearchFilterSidebar/expandCollapseHeading.vue";
import FilterCount from "@/views/Search/SearchFilterSidebar/FilterCount.vue";

interface FacetValueCount {
  value: string;
  count: number;
}

export default defineComponent({
  components: { expandCollapseHeading, FilterCount },
  emits: ["toggle-all", "toggle-path-all", "expanded", "filter-selected"],
  expose: ["collapse", "selectedFilters"],
  props: {
    facets: {
      type: Object,
      default: null,
    },
    pathFacets: {
      type: Object,
      default: null,
    },
    courseFacets: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      expanded: true,
      expandedPath: true,
      filters: [] as string[],
      filtersType: [] as string[],
    };
  },
  computed: {
    routeQueryType() {
      return this.$route.query && this.$route.query.type
        ? this.$route.query.type
        : [];
    },
    pathFilters() {
      return [
        {
          type: "assessments",
          value: "path",
          label: "assessments",
          count: this.facets.data.filter(
            (facet: FacetValueCount) => facet.value == "assessments"
          )[0]?.count,
        },
        {
          type: "labs",
          value: "path",
          label: "labs",
          count: this.facets.data.filter(
            (facet: FacetValueCount) => facet.value == "labs"
          )[0]?.count,
        },
      ];
    },
    pathHasAssessments() {
      return this.pathFacets?.number_of_assessments[0].data[0].count;
    },
    pathHasLabs() {
      return this.pathFacets?.number_of_labs[0].data[0].count;
    },
    pathHasCredits() {
      return this.pathFacets?.number_of_credits[0].data[0].count;
    },

    contentTypes() {
      let results = [] as Array<FacetValueCount>;
      const routerName: string = this.$route.name as string;
      const sortingArray = [
        "path",
        "course",
        "challenge",
        "practice-exam",
        "instructor-led",
        "tutorial",
        "video",
        "video-track",
        "video-series",
        "podcast",
        "webinar",
      ];
      if (!window.env.ENABLE_CTF) {
        sortingArray.splice(sortingArray.indexOf("challenge"), 1);
      }
      if (!this.facets || !this.facets.data) {
        return results;
      }
      this.facets.data.forEach((element: FacetValueCount) => {
        if (!sortingArray.includes(element.value)) return;

        if (element.value == "course" && this.courseFacets) {
          results.push(this.courseFacets.type[0].data[0]);
        } else if (element.value == "video-track") {
          results.push({
            value: "video-series",
            count: element.count,
          });
        } else if (
          element.value != "webinar" ||
          ["all", "search-all", "explore-events"].includes(routerName)
        ) {
          results.push(element);
        }
      });

      results = results.sort(function (a, b) {
        return sortingArray.indexOf(a.value) - sortingArray.indexOf(b.value);
      });

      if (
        ["all", "explore-type"].includes(routerName) ||
        routerName?.startsWith("explore-")
      ) {
        results = results.filter((result) => result.value != "video");
      }
      return results;
    },
  },
  mounted() {
    this.filtersType = [this.routeQueryType as string[]].flat();
  },
  methods: {
    formatContentTypes,
    collapse(expand: boolean) {
      this.expanded = expand;
    },
    selectedFilters() {
      let filters: Array<string> = JSON.parse(
        JSON.stringify(this.filtersType)
      )?.map((filterType: string) => {
        return {
          name: `type`,
          keys: [filterType],
        };
      });
      return filters?.length ? filters : [];
    },
    startCase,
    expandCollapseInternal() {
      this.expanded = !this.expanded;
      this.$emit("expanded", true);
    },
    ToggleFromModel(value: string) {
      if (this.filtersType.includes(value)) {
        this.filtersType = this.filtersType.filter(
          (matchingValue) => matchingValue !== value
        );
      } else {
        this.filtersType.push(value);
      }
      this.$emit("filter-selected");
    },
    isCurrent(value: string): boolean {
      value = value.toLowerCase();
      let queryType = this.routeQueryType;
      let routeName: any = this.$route.name;

      if (queryType.includes(value)) {
        return true;
      }
      if (
        routeName?.startsWith("explore-") ||
        this.$route.name == "search-type"
      ) {
        let exploreType: any = this.$route?.params?.type
          ? this.$route.params.type
          : this.$route?.meta?.type;
        // usually this equates to something like "courses".includes("course") or "events-and-webinars".includes("webinar")
        return exploreType != undefined && exploreType.includes(value);
      }
      return false;
    },
  },
});
</script>

<template>
  <div class="min-w-[17.5rem]" v-show="contentTypes.length">
    <div class="mr-4 mt-4 border-t">
      <expandCollapseHeading
        :data-cy="'search-filter-heading-content-type'"
        @expand-collapse-internal="expandCollapseInternal"
        :expanded="expanded"
      >
        Content Type
      </expandCollapseHeading>
      <div v-if="expanded" data-cy="search-filter-content-type-field-list">
        <ul class="p-1" v-for="content in contentTypes" :key="content.value">
          <li
            v-if="content.value != 'path'"
            data-cy="content-type-field"
            class="flex flex-row items-center justify-between whitespace-nowrap text-16 tracking-[125%] text-black-dark"
          >
            <cu-checkbox
              data-cy="contentType"
              class="cursor-pointer"
              @input="$emit('toggle-all', content.value)"
              :checked="isCurrent(content.value)"
            >
              {{ formatContentTypes(content.value) }}
              <filterCount>({{ content.count }})</filterCount>
            </cu-checkbox>
          </li>
          <li
            v-else
            data-cy="content-type-field"
            class="whitespace-nowrap text-16 tracking-[125%] text-black-dark"
          >
            <div
              class="relative flex w-full items-center justify-between p-0 text-left text-22 font-light leading-[120%] -tracking-[0.02em] text-black-dark"
            >
              <span class="sr-only" v-if="expanded">Close filters for </span>
              <span class="sr-only" v-else>Open filters for</span>
              <cu-checkbox
                data-cy="contentType"
                class="cursor-pointer"
                @input="$emit('toggle-all', content.value)"
                :checked="isCurrent(content.value)"
              >
                Learning Path
                <filterCount class="mr-2 text-16"
                  >({{ content.count }})</filterCount
                >
              </cu-checkbox>
              <button
                data-cy="search-filter-heading-path"
                tabindex="0"
                @click.prevent="expandedPath = !expandedPath"
                @keyup.space.prevent="expandedPath = !expandedPath"
                @keyup.enter.prevent="expandedPath = !expandedPath"
              >
                <span class="sr-only" v-if="expandedPath"
                  >Close filters for Learning Path</span
                >
                <span class="sr-only" v-if="!expandedPath"
                  >Open filters for Learning Path</span
                >
                <cu-icon-chevron-up
                  v-if="!expanded"
                  class="inline-block cursor-pointer text-12 hover:text-blue-lightest"
                ></cu-icon-chevron-up>
                <cu-icon-chevron-down
                  v-else
                  class="inline-block cursor-pointer text-12 hover:text-blue-lightest"
                ></cu-icon-chevron-down>
              </button>
            </div>

            <ul v-if="expandedPath" class="ml-10">
              <li
                class="flex flex-row items-center justify-between"
                v-for="filter in pathFilters"
                :key="filter.type"
              >
                <cu-checkbox
                  data-cy="pathFilter"
                  class="cursor-pointer"
                  :checked="isCurrent(filter.type)"
                  @input="ToggleFromModel(filter.type)"
                >
                  {{ startCase(filter.label) }}
                  <filterCount
                    >({{ filter.count ? filter.count : 0 }})</filterCount
                  >
                </cu-checkbox>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
