import { z } from "zod";

import http from "@/services/http";

const globalProduct = z
  .object({
    id: z.number(),
    content_bundle_id: z.number(),
    global_product_id: z.number(),
    global_product_name: z.string(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
  })
  .passthrough();

const catalogData = z
  .object({
    id: z.number(),
    active: z.number(),
    adjacent_technology: z.string().nullish(),
    acronym: z.string().nullish(),
    author: z.string().nullish(),
    content_type_id: z.number().nullish(),
    copyright_holder: z.string().nullish(),
    copyright_year: z.number().nullish(),
    global_products: z.array(globalProduct).nullish(),
    data_classification: z.string().nullish(),
    description: z.string(),
    duration_sec: z.number(),
    guid: z.string(),
    is_partial: z.number().nullish(),
    is_private: z.number().nullish(),
    product_alias: z.string().nullish(),
    product_id: z.number().nullish(),
    product_version: z.string().nullish(),
    publisher_name: z.string().nullish(),
    technology: z.string().nullish(),
    title: z.string().nullish(),
    type: z.string(),
    url: z.string().nullish(),
    event_date: z.string().datetime().nullish(),
    associated_descriptions: z.array(z.string()).nullish(),
    associated_titles: z.array(z.string()).nullish(),
    associated_guids: z.array(z.string()).nullish(),
    associated_certifications: z.array(z.string()).nullish(),
    name: z.string().nullish(),
    authoring_platform: z.string().nullish(),
    cisco_be: z.string().nullish(),
    content_bundle: z.array(z.number()).nullish(),
    content_provider: z.string().nullish(),
    presenter: z.string().nullish(),
    publisher_id: z.number().nullish(),
    solutions: z.array(z.string()).nullish(),
    number_of_labs: z.number().nullish(),
    version: z.any().nullish(),
    training_modality: z.string().nullish(),
    job_roles: z.any().nullish(),
    product: z.string().nullish(),
    skills_associated: z
      .array(z.string())
      .nullish()
      .or(z.string().default("").nullish()),
    difficulty_type: z.string().nullish(),
    difficulty_type_id: z.number().nullish(),
    references: z.array(z.string()).nullish(),
    summary: z.string().nullish(),
    display_version: z.string().nullish(),
    sort_order: z.number().nullish(),
    authored_at: z.string().nullish(),
    keywords: z.any().nullish(),
    certification_level: z.array(z.string()).nullish(),
    certifications: z.array(z.string()).nullish(),
    content_count: z.number().nullish(),
    number_of_assessments: z.number().nullish(),
    number_of_credits: z.number().nullish(),
    exam_number: z.string().nullish(),
    notes: z.string().nullish(),
    webex_url: z.string().nullish(),
    solution: z.string().nullish(),
    tracks: z.array(z.string()).nullish(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
  })
  .passthrough();

const recommendationType = z
  .object({
    CourseID: z.string().nullish(),
    CourseTitle: z.string().nullish(),
    CourseType: z.string().nullish(),
    RelevancyScore: z.string().nullish(),
    TrackID: z.string().nullish(),
    Confidence: z.string().nullish(),
    TrackTitle: z.string().nullish(),
    TrackUID: z.string().nullish(),
    Type: z.string().nullish(),
    catalog_data: catalogData,
  })
  .passthrough();

const recommendationPath = z
  .object({
    PathID: z.string().nullish(),
    PathTitle: z.string(),
    PathGUID: z.string().nullish(),
    Confidence: z.string().nullish(),
    Type: z.string().nullish(),
    catalog_data: catalogData,
  })
  .passthrough();

const recommendationResponse = z.array(recommendationType);
const recommendationPathResponse = z.array(recommendationPath);

export async function podcasts() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsPodcasts`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function courses() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsCourses`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function challenges() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsChallenges`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function paths() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsPaths`,
    {}
  );

  return recommendationPathResponse.parse(data);
}

export async function pathsByTopics(topics: string[]) {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsPaths`,
    {
      topics: topics,
    }
  );

  return recommendationPathResponse.parse(data);
}

export async function events() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsWebinars`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function tutorials() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsTutorials`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function videos() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsVideos`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function instructorLed() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsIlts`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function practiceExams() {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetRecommendationsPracticeExams`,
    {}
  );

  return recommendationResponse.parse(data);
}

export async function certificationRecommendedPaths(guids: Array<string>) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/retrieveCatalogItems`,
    {
      type: "path",
      Guids: guids,
    }
  );
  return data;
}

export async function getTopOfFunnelRecommendations(certPreference: string) {
  const { data } = await http().post(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/GetTopOfFunnelRecommendations`,
    {
      certPreference: certPreference,
      recommendationLimit: "1",
    }
  );

  return data;
}

export async function checkCeCredits(pathGuid: string) {
  const { data } = await http().get(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/user-activities?pathGuid=${pathGuid}`
  );

  return data;
}

export async function ceEligibility(acronym: string) {
  const { data } = await http().get(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/eligibility?courseGroup=${acronym}`
  );

  return data;
}
