import http from "@/services/http";

export async function fetchChallenge(id: any) {
  const { data } = await http()
    .get(`${window.env.CATALOG_API_LOCATION}/courses/${id}`)
    .catch((e) => {
      return e.response.data;
    });
  return data;
}
