import { z } from "zod";

import {
  processQueryResponse,
  processUserRequest,
} from "@/services/genAiService/types";

import http, { localStoreOrCookie } from "../http";

export type ProcessQueryRequest = z.infer<typeof processUserRequest>;

export async function processQuery(postRequest: ProcessQueryRequest) {
  processUserRequest.parse(postRequest);
  const { data } = await http().post(
    `${window.env.GEN_AI_API_LOCATION}/process_query_streaming`,
    postRequest
  );
  return processQueryResponse.parse(data);
}

export default async function handleProcessQuery(
  postRequest: ProcessQueryRequest,
  onChunk: (chunk: string) => void
) {
  const apiToken = localStoreOrCookie("x-lacp-secure");
  const reqHeader = new Headers();
  reqHeader.append("Content-Type", "application/json");
  reqHeader.append("Authorization", "Bearer " + apiToken);
  reqHeader.append("x-lacp-secure", `${apiToken}`);
  reqHeader.append("x-lacp-profile", `${localStoreOrCookie("x-lacp-profile")}`);
  reqHeader.append("applicationId", `${window.env.APPLICATION_ID}`);
  reqHeader.append("session-id", `${localStoreOrCookie("session-id")}`);

  try {
    const response = await fetch(
      `${window.env.GEN_AI_API_LOCATION}/process_query_streaming`,
      {
        method: "POST",
        credentials: "include",
        mode: "cors",
        headers: reqHeader,
        body: JSON.stringify(postRequest),
      }
    );

    if (!response.ok) {
      return; // Exit if response is not OK
    }

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      let firstChunkTime = null;
      const startTime = performance.now();
      let done = false;

      do {
        const { value, done: chunkDone } = await reader.read();
        done = chunkDone;
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        if (!firstChunkTime) {
          firstChunkTime = performance.now();
          console.log(
            `First chunk received in: ${(firstChunkTime - startTime).toFixed(
              2
            )} ms`
          );
        }

        onChunk(chunk);
      } while (!done);

      const endTime = performance.now();
      console.log(
        `Total time for handling submission: ${(endTime - startTime).toFixed(
          2
        )} s`
      );
    } else {
    }
  } catch (error) {}
}
