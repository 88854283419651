<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-left.js";

import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import exploreBannerOne from "@/assets/images/explore-banner-1.png?url";
import CertCard from "@/components/contentBits/certCard.vue";
import challengeResult from "@/components/contentBits/challengeResult.vue";
import EventResult from "@/components/contentBits/eventsResult.vue";
import instructorLedResult from "@/components/contentBits/instructorLedResult.vue";
import LearningPathResult from "@/components/contentBits/learningPathResult.vue";
import PodCastCard from "@/components/contentBits/podCastCard.vue";
import practiceExamResult from "@/components/contentBits/practiceExamResult.vue";
import tutorialsResult from "@/components/contentBits/tutorialsResult.vue";
import VideoCard from "@/components/contentBits/videosCard.vue";
import searchService from "@/services/searchService";
import { sendButtonCtaTelemetry } from "@/utils/ctaTelemetry";
import flattenSearchResult from "@/utils/flattenSearchResult";
import isUnauth from "@/utils/isUnauth";
import resultMeta from "@/views/Search/assets/resultMeta.json";
import SkeletonLoader from "@/views/Skeleton/Search/SkeletonLoader.vue";

const learningPaths = "learning-paths";
const instructorLed = "instructor-led";

export default defineComponent({
  emits: ["see-more"],
  props: {
    resultType: {
      type: String,
      default: null,
    },
    page: {
      type: Object,
      default: () => ({
        current: 1,
        size: 6,
      }),
    },
    results: {
      type: Array,
      default: () => [],
    },
    requestId: {
      type: String,
      default: "",
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
  },
  components: {
    CertCard,
    PodCastCard,
    LearningPathResult,
    EventResult,
    VideoCard,
    tutorialsResult,
    challengeResult,
    SkeletonLoader,
    instructorLedResult,
    practiceExamResult,
  },
  data() {
    return {
      transitionKey: 0,
      isListView: true,
      exploreBannerOne,
      joinCiscoText: "Join Cisco U. Free",
      loginText: "Log in with your Cisco ID",
    };
  },
  computed: {
    resultsWithBannerForUnauth(): any[] {
      let ogResults = this.results;

      if (this.isUnauth() && this.displayMidBanner) {
        // splice in { isBanner: true } at the index defined by bannerInsertion
        // increasing the results array length by 1
        let results = [...ogResults];
        let bannerInsertion = this.bannerInsertion;
        results.splice(bannerInsertion, 0, { isBanner: true });
        return results;
      }
      return ogResults;
    },
    ...mapGetters({
      status: "user/status",
    }),
    isSearchRoute() {
      return this?.$route?.name == "search-type";
    },
    resultMeta() {
      const resultMap = {
        course: resultMeta[0],
        courses: resultMeta[0],
        "learning-paths": resultMeta[1],
        path: resultMeta[1],
        podcast: resultMeta[2],
        podcasts: resultMeta[2],
        webinar: resultMeta[3],
        "events-and-webinars": resultMeta[3],
        video: resultMeta[4],
        videos: resultMeta[4],
        "video-track": resultMeta[5],
        "video-series": resultMeta[5],
        video_series: resultMeta[5],
        tutorial: resultMeta[6],
        tutorials: resultMeta[6],
        ilt: resultMeta[7],
        "instructor-led": resultMeta[7],
        "practice-exam": resultMeta[8],
        "practice-exams": resultMeta[8],
        challenge: resultMeta[9],
        challenges: resultMeta[9],
      };
      return (
        resultMap[this.resultType as keyof typeof resultMap] ?? {
          name: "",
          cyId: "",
          resultComponent: "",
        }
      );
    },
    isLargeCards() {
      return [learningPaths, instructorLed, "tutorials", "courses"].includes(
        this.$route?.meta?.type as string
      );
    },
    bannerInsertion() {
      return [learningPaths, instructorLed, "tutorials", "courses"].includes(
        this.$route?.meta?.type as string
      )
        ? 6
        : 5;
    },
    displayMidBanner() {
      return [learningPaths, "courses", instructorLed, "tutorials"].includes(
        this.$route?.meta?.type as string
      );
    },
  },
  methods: {
    isUnauth,
    flattenSearchResult,
    trackClick(result: any) {
      if (!this.isSearchRoute) return;
      searchService.trackClickThrough.trackClickThrough(
        {
          request_id: this.requestId,
          result: result,
          tenant: ["apollo"],
          query: this.$route?.query?.query as string,
          score: result._meta.score,
          page: JSON.parse(JSON.stringify(this.page)),
          url: window.location.href,
        },
        this.isUnauth()
      );
    },
    async sendTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendButtonCtaTelemetry(this.$route.fullPath, title, true);
      }
      window.location.href = window.location.origin + "/login";
    },
  },
});
</script>

<template>
  <div class="my-8 w-full xl:w-[88.3%]">
    <div v-if="isSearchRoute" class="mb-4">
      <button
        tabindex="0"
        data-cy="see-more-button"
        @click="$emit('see-more')"
        @keyup.enter="$emit('see-more')"
        @keyup.space="$emit('see-more')"
        class="mb-9 mt-[1.5625rem] inline-flex cursor-pointer items-center justify-between"
      >
        <cu-icon-chevron-left
          class="mr-1 inline-block text-xs text-blue-light hover:text-blue"
        />
        <span
          :data-cy="resultMeta.cyId"
          class="text-16 text-blue-light hover:text-blue md:relative md:bottom-0 md:inline-block"
        >
          Back to all results for "{{ $route?.query?.query as string }}"
        </span>
      </button>
      <h2
        data-cy="searchFilterHeading"
        class="order-[0] flex-none grow-0 text-29 font-light leading-[120%] text-black-dark"
      >
        Relevant <strong>{{ resultMeta.name }}</strong>
      </h2>
    </div>
    <div
      v-else
      class="flex w-full grow flex-col items-start justify-between lg:flex-row xl:pl-2"
    >
      <h2
        class="flex text-29 font-light leading-[120%] text-black-dark lg:pl-2"
      >
        <span>
          Explore all <strong>{{ resultMeta.name }}</strong>
        </span>
      </h2>
      <slot></slot>
    </div>
    <skeleton-loader v-if="isSearching && results.length == 0" />
    <div
      :class="[
        'mt-7 flex flex-wrap',
        {
          'flex-row': cardView == 'Grid',
          'flex-col': cardView != 'Grid',
        },
      ]"
      v-else
    >
      <transition-group name="list" appear :key="transitionKey">
        <div v-for="(result, index) in resultsWithBannerForUnauth" :key="index">
          <div
            v-if="result.isBanner"
            :class="[
              'mb-4 w-full flex-auto pl-4',
              {
                'max-w-[60rem]': isLargeCards,
              },
            ]"
          >
            <cu-banner
              dark=""
              bannerpadding="sm"
              data-cy="mid-explore-banner"
              :rightsrc="exploreBannerOne"
              class="mid-explore-banner"
            >
              <h3>A Cisco U. Free account is waiting for you</h3>
              <p>
                No matter what learning you choose, easily follow your progress
                and learning history. <br />Need help deciding what to learn?
                You'll also view personalized recommendations
              </p>
              <div class="buttons">
                <cu-buttons
                  btntype="primary"
                  :value="joinCiscoText"
                  size="sm"
                  @click="() => sendTelemetry(joinCiscoText)"
                ></cu-buttons>
                <cu-buttons
                  btntype="tertiary"
                  :value="loginText"
                  size="sm"
                  @click="() => sendTelemetry(loginText)"
                ></cu-buttons>
              </div>
            </cu-banner>
          </div>
          <div
            v-else
            class="translate-z-0 relative mb-4 ml-4 flex hover:z-[700] focus:z-[700] active:z-[700]"
          >
            <component
              :is="resultMeta.resultComponent"
              :result="flattenSearchResult(result)"
              :status="status"
              :card-view="cardView"
              @click="trackClick(result)"
              tabindex="0"
            />
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style scoped>
.mid-explore-banner {
  --cu-banner-img-right-size: 15.375rem;
  --cu-banner-img-right-pos-right: -2.5rem;
  --cu-banner-img-right-pos-top: -6rem;
}
</style>
