<script lang="ts">
import "@cisco-u/card/cu-card.js";
import "@cisco-u/new-card/cu-new-card.js";

import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import theCard from "@/components/contentBits/theCard.vue";
import catalogService from "@/services/catalogService";
import { EventBus } from "@/store/eventBus";
import getDuration from "@/utils/getDuration";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  components: {
    theCard,
  },
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default: null,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
  },
  data() {
    return {
      paths: null as any,
      vcoConfig: {
        handler: this.hideClicked,
        events: ["dblclick", "click"],
        capture: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
      contentAuthorized: "content/authorizedContent",
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      return this.result?.content_bundle?.includes(this.freeId);
    },
    showPremium(): boolean {
      return !this.status?.userEntitlements?.includes(this.result?.guid);
    },
    showChallenge() {
      return window.env.ENABLE_CTF;
    },
  },
  methods: {
    isUnauth,
    getDuration,
    ...mapActions({
      fetchListByType: "user/fetchListByType",
      removeContentFromMyList: "user/removeContentFromMyList",
      addContentToMyList: "user/addContentToMyList",
    }),

    bookmarkMe(content: any) {
      this.inMyList(content)
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: content,
          })
        : this.addContentToMyList({
            routeName: this.$route.name,
            result: content,
          });
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),
    whereTo() {
      this.sendTelemetry();
    },
    resourceUrl() {
      return "/challenge/" + this.result.id;
    },
    openCartModal() {
      EventBus.emit("openCartModal", {
        data: this.paths,
        courseCard: true,
      });
    },
    async getCoursePaths(id: number) {
      await catalogService.courses
        .getCoursePaths(id)
        .then((data) => {
          this.paths = data?.paths;
        })
        .catch(() => {
          this.$router.push({ name: "error" });
        });
    },
    async sendTelemetry() {
      let telemetryResponse = telemetry.external(
        {
          action: "launch-contentType",
          url: this.result.url,
          title: this.result.title,
          id: this.result.id,
          page: this.$route.fullPath,
          type: this.result.type,
          relevance: this.result.relevance,
          sortOrder: this.index,
        },
        this.isUnauth()
      );
      telemetryResponse
        .then(() => {
          this.$router.push(this.resourceUrl());
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
</script>

<template>
  <div
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView === 'Grid',
        'w-full': cardView != 'Grid',
      },
    ]"
    data-cy="challenge-result"
    v-if="showChallenge"
    v-show="result"
  >
    <the-card
      :is-unauth="isUnauth()"
      :card-view="cardView"
      :result="result"
      :status="status"
      :cy="'challenge-card'"
      @navigate="whereTo"
      @bookmark="debouncedBookmarkMe(result)"
    />
  </div>
</template>
