import { z } from "zod";

import http from "@/services/http";

const myProfileResponseCourses = z
  .array(
    z
      .object({
        courseCompletionDate: z.string(),
        courseCompletionEventID: z.string(),
        courseGUID: z.string(),
        courseID: z.string(),
        solomonuuid: z.string(),
        technology: z.string(),
        title: z.string(),
        vendorID: z.string(),
      })
      .optional()
  )
  .optional();

const myProfileResponseCoursesProgess = z
  .array(
    z
      .object({
        courseCompletionDate: z.string(),
        courseCompletionEventID: z.string(),
        courseCompletionPercentage: z.string(),
        courseGUID: z.string(),
        courseID: z.string(),
        solomonuuid: z.string(),
        technology: z.string(),
        title: z.string(),
        vendorID: z.string(),
      })
      .optional()
  )
  .optional();

const myProfileBadges = z
  .array(
    z
      .object({
        badgeID: z.string(),
        badgeName: z.string(),
        badgeTemplateID: z.string(),
        badgingRuleID: z.string(),
        expiryDate: z.string(),
        issueDate: z.string(),
      })
      .optional()
  )
  .nullish();

const myCerts = z
  .array(
    z
      .object({
        certAcronym: z.string(),
        certDecertDate: z.string(),
        certExpiryDate: z.string(),
        certLevel: z.string(),
        certOriginalDate: z.string(),
        certTemplateId: z.string(),
        certTemplateName: z.string(),
      })
      .optional()
  )
  .nullish();

const myProfileResponse = z.object({
  myLearningList: z.array(z.any()).optional(),
  achievements: z
    .object({
      courses: z
        .object({
          completions: myProfileResponseCourses,
          "In-progress": myProfileResponseCoursesProgess,
        })
        .optional(),
      badges: z
        .object({
          awarded: myProfileBadges,
        })
        .optional(),
      certificates: z
        .object({
          certified: myCerts,
          decertified: myCerts,
        })
        .optional(),
      assessment: z
        .object({
          vendorID: z.string(),
          application: z.string(),
          environment: z.string(),
          "latest-attemp-details": z.any(),
          "pre-assessment-attempt": z.any(),
          "post-assessment-attempt": z.any(),
        })
        .optional(),
      practiceExams: z
        .object({
          completions: z.array(z.any()).nullish(),
        })
        .optional(),
    })
    .optional(),
});

export type MyProfileResponse = z.infer<typeof myProfileResponse>;
export type MyProfileResponseCourses = z.infer<typeof myProfileResponseCourses>;
export type MyProfileBadges = z.infer<typeof myProfileBadges>;
export type MyCerts = z.infer<typeof myCerts>;

export async function fetchMyProfile() {
  if (!window.env.PROFILE_API_LOCATION) {
    return;
  }
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/myProfile`
  );

  return myProfileResponse.parse(data);
}
