<script lang="ts">
//@ts-ignore
import "@cisco-u/icons/icons/cu-icon-associate.js";
import "@cisco-u/icons/icons/cu-icon-expert.js";
import "@cisco-u/icons/icons/cu-icon-professional.js";
import "@cisco-u/icons/icons/cu-icon-novice.js";

import { defineComponent } from "vue";

import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  props: {
    root: {
      type: String,
      default: "",
    },
    location: {
      type: String,
      default: "",
    },
    level: {
      type: String,
      default: "",
    },
  },
  methods: {
    isUnauth,
  },
});
</script>

<template>
  <div class="pl-8">
    <span class="breadcrumb pb-5">
      <router-link
        to="/certifications"
        :class="[
          'text-gray',
          {
            'text-16 font-light': isUnauth(),
            'text-[1.2rem]': !isUnauth(),
          },
        ]"
      >
        {{ root }}
      </router-link>
      /
      <span
        :class="[
          'text-black-darket',
          {
            'text-16 font-light': isUnauth(),
            'text-[1.2rem]': !isUnauth(),
          },
        ]"
        >{{ location }}</span
      >
    </span>
    <div class="py-10" :class="{ flex: isUnauth() }">
      <span class="pr-2">
        <cu-icon-associate
          v-if="level === 'Associate'"
          class="text-20 text-blue-light"
        />
        <cu-icon-expert
          v-else-if="level === 'Expert'"
          class="text-20 text-black-dark"
        />
        <cu-icon-professional
          v-else-if="level === 'Professional'"
          class="text-20 text-blue-dark"
        />
        <cu-icon-novice
          v-else-if="level === 'Entry'"
          class="text-20 text-blue-lightest"
        />
      </span>
      <span
        :class="[
          {
            'text-14 font-normal tracking-md': isUnauth(),
            'text-20 text-black-lightest': !isUnauth(),
          },
        ]"
        >{{ level.toUpperCase() }}</span
      >
    </div>
  </div>
</template>
